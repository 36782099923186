var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade",attrs:{"id":"pills-transactions","role":"tabpanel","aria-labelledby":"pills-transactions-tab"}},[_c('div',{staticClass:"d-flex justify-content-between py-5"},[_c('label',{staticClass:"d-block has-checkbox text--black text--500 text--regular",attrs:{"for":"showadjusted"}},[_vm._v("Show Adjusted Transactions "),_c('input',{staticClass:"form-",attrs:{"type":"checkbox","id":"showadjusted"},on:{"click":_vm.changeTransactionStatus}}),_c('span',{staticClass:"checkmark"})])]),_c('table',{staticClass:"customtable w-100"},[_vm._m(0),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.transactionId},[_c('td',[_c('a',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital text--primary',
            ],attrs:{"href":"customer-profile.html"}},[_vm._v(" "+_vm._s(item.user ? item.user : "NA"))])]),_c('td',[_c('span',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital',
            ]},[_vm._v(_vm._s(item.transactionId))])]),_c('td',[_c('span',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital',
            ]},[_vm._v(_vm._s(_vm._f("moment")(item.valueDate,"DD/MM/YYYY")))])]),_c('td',[_c('span',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital pr-3',
            ]},[_vm._v(_vm._s(item.type))])]),_c('td',[_c('span',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital',
            ]},[_vm._v(_vm._s(_vm.toNaira(item.amount)))])]),_c('td',[_c('span',{class:[
              item.isReversed ? 'text--opacity' : '',
              'table__body__text table__text text--capital',
            ]},[_vm._v(_vm._s(_vm.toNaira(item.totalBalance)))])]),_c('td',{staticStyle:{"padding-right":"0 !important"}},[_c('div',{staticClass:"align-self-center mx-2 dropdown"},[_c('button',{staticClass:"button button--grey text--capital text--small d-flex align-items-center",attrs:{"type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" action "),_c('span',{staticClass:"dropdown__icon"},[_c('ion-icon',{attrs:{"name":"caret-down-outline"}})],1)]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuButton"}},[_c('a',{staticClass:"dropdown-item text--capital",on:{"click":function($event){return _vm.view(item)}}},[_vm._v("View")]),(!item.isReversed)?_c('a',{staticClass:"dropdown-item text--capital",on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Adjust")]):_vm._e()])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"17%"},attrs:{"scope":"col"}},[_vm._v(" User ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"12%"},attrs:{"scope":"col"}},[_vm._v(" ID ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"15%"},attrs:{"scope":"col"}},[_vm._v(" Value Date (Entry Date) ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"15%"},attrs:{"scope":"col"}},[_vm._v(" Type ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"12%"},attrs:{"scope":"col"}},[_vm._v(" Amount ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"12%"},attrs:{"scope":"col"}},[_vm._v(" Total Balance ")]),_c('th',{staticClass:"table__header__text table__text text--capital",staticStyle:{"width":"15%"},attrs:{"scope":"col"}},[_vm._v(" Actions ")])])])
}]

export { render, staticRenderFns }