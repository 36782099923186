<template>
  <div class="row mt5">
    <div class="col-6">
      <div class="account_item_head">
        <p class="account_item_head_text text--white">general</p>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Account ID
          </span>
          <span
            class="text--black text--regular account_item_subtitle overlap"
            :title="
              data ? (data.uniqueLoanId ? data.uniqueLoanId : 'NA') : 'NA'
            "
          >
            {{ data ? (data.uniqueLoanId ? data.uniqueLoanId : "NA") : "NA" }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Assigned to Branch
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data ? data.assignedBranchName : "" }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Account State
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.accountSubState
                  ? data.accountState + "(" + data.accountSubState + ")"
                  : data.accountState
                : ""
            }}
            <!-- {{ data ? data.accountSubState ? data.accountSubState : data.accountState : "" }} -->
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Original Account
          </span>
          <span
            class="text--black text--regular account_item_subtitle text--primary overlap"
          >
            {{ data.originalAccount }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Currency
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            NGN
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Activation Date
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.activationDate | moment }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Product
          </span>
          <span
            class="text--black text--regular account_item_subtitle text--primary overlap"
          >
            {{
              data
                ? data.adminLoanProduct
                  ? data.adminLoanProduct.product_name
                  : "NA"
                : "NA"
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Product Type
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            Fixed Term Loan
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Display name
          </span>
          <span
            class="text--black text--regular account_item_subtitle text--primary overlap"
          >
            {{ data ? data.loanName : "NA" }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Loan Amount
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.loanAmount | currency }}
          </span>
        </div>
        <div class="col-6 mt-3">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Interest Rate
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data ? (data.interestRate !== null ? data.interestRate : "") : ""
            }}
            {{ this.interestRateDisplay }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Interest Calculation Method
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.adminLoanProduct !== null
                  ? data.adminLoanProduct.interestCalculationMethod !== null
                    ? interestCalculationMethod(
                        data.adminLoanProduct.interest_calculation_method
                      )
                    : ""
                  : ""
                : ""
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Accrued Interest Posting Frequency
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.adminLoanProduct
                  ? accruedInterestPostingFrequency(
                      data.adminLoanProduct.interest_application_method
                    )
                  : "NA"
                : "NA"
            }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Arrears Tolerance Period
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.arrearsTolerancePeriod !== null ||
                  data.arrearsTolerancePeriod !== undefined
                  ? data.arrearsTolerancePeriod + " days"
                  : "NA"
                : "NA"
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            With a floor (minimum)
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.adminLoanProduct
                  ? data.adminLoanProduct.generalArrearsSetting
                    ? data.adminLoanProduct.generalArrearsSetting
                        .toleranceFloorAmount
                      ? ""
                      : ""
                    : ""
                  : ""
                : ""
            }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Repayment allocation method
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            Horizontal
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Repayments are made every
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.repaymentPeriodCount
                  ? data.repaymentPeriodCount
                  : ""
                : ""
            }}
            <!-- {{ loanInterestType }} -->
            {{
              repaymentAreMadeEvery(
                data.adminLoanProduct.repayment_schedule_method
              )
            }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Number of Installments
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.repaymentInstallments
                  ? data.repaymentInstallments
                  : "NA"
                : "NA"
            }}
            Installments
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Collect principal every
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.defaultPrincipalRepaymentInterval }}
            {{
              data.defaultPrincipalRepaymentInterval > 1
                ? "Repayments"
                : "Repayment"
            }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            pre-payment Acceptance
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.adminLoanProduct
                  ? data.adminLoanProduct.future_payments_acceptance === 0
                    ? "Yes"
                    : "No"
                  : "No"
                : "No"
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Accept Pre-payment of Future Interest
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data
                ? data.adminLoanProduct
                  ? data.adminLoanProduct.future_payments_acceptance === 0
                    ? "Accept interest pre-payments"
                    : "Accept Postdated Payments"
                  : "No"
                : "No"
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Requires Offer Acceptance
          </span>
          <span class="text--black text--regular account_item_subtitle">
            {{ RequireOfferAcceptance ? "Yes" : "No" }}
          </span>
        </div>

        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Allow Override
          </span>
          <span class="text--black text--regular account_item_subtitle">
            {{ AllowUnderwriterOveride }}
          </span>
        </div>

        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Acceptance Status
          </span>
          <span class="text--black text--regular account_item_subtitle">
            {{ data.acceptanceStatus }}
          </span>
        </div>

        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Over ride Action
          </span>
          <span class="text--black text--regular account_item_subtitle">
            {{
              isOVerRide
                ? `${data.overrideStatus} by ${data.underWriterName}`
                : data.overrideStatus
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="account_item_head">
        <p class="account_item_head_text text--white">Details</p>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Principal Balance
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.principalBalance | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Interest Balance
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            <!-- {{ data.balances.interestBalance | currency }} -->
            {{ data.balances.interestDue | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <!-- <div class="col-6">
          <span
            class="
              text--black text--opaq2 text--small text--bold
              account_item_title
            "
          >
            Interest Incurred
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data.balances.interestBalance | currency
            }}
          </span>
        </div> -->
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Fee Balance
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.feesBalance | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Penalty Balance
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.penaltyBalance | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Total Due
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.totalDue | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Principal Due
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.principalDue | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Interest Due
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.interestDue | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Fee Due
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.feesDue | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Penalty Due
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.penaltyDue | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Total Paid
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ totalPaid(data.balances) | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Principal Paid
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.principalPaid | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Interest Paid
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.interestPaid | currency }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Fees Paid
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.feesPaid | currency }}
          </span>
        </div>
      </div>
      <div class="row no-gutters border-0 account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Penalty Paid
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.balances.penaltyPaid | currency }}
          </span>
        </div>

        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Penalty Rate
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.penaltyRate }} %
          </span>
        </div>

        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Accrued Interest
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.accruedInterest | currency }}
          </span>
        </div>
      </div>

      <!--      <div class="row mt5">-->
      <!--        <div class="col-12">-->
      <!--          <div class="account_item_head">-->
      <!--            <p class="account_item_head_text text&#45;&#45;white">Settlement Account</p>-->
      <!--          </div>-->
      <!--          <div class="row no-gutters account_item align-items-center">-->
      <!--            <div class="col-6">-->
      <!--              <span-->
      <!--                class="text&#45;&#45;black text&#45;&#45;opaq2 text&#45;&#45;small text&#45;&#45;bold account_item_title"-->
      <!--              >-->
      <!--                Settlement Account-->
      <!--              </span>-->
      <!--              <span class="text&#45;&#45;black text&#45;&#45;regular account_item_subtitle">-->
      <!--                1100891242-->
      <!--              </span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="col-6 mt-5">
      <div class="account_item_head">
        <p class="account_item_head_text text--white">Disbursement Details</p>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Anticipated Disburse Date
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data.anticipatedDisbursementDetails.anticipatedDisbursementDate
                | moment
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Disbursed Date
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.disbursementDetails.disbursementDate | moment }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Anticipated Disbursement Account
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data.anticipatedDisbursementDetails.anticipatedBankAccountNumber
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Disbursed Account Number
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.disbursementDetails.disbursedAccountNumber }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Anticipated Disbursement Bank
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.anticipatedDisbursementDetails.anticipatedBankName }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Disbursed to Bank
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.disbursementDetails.disbursedBankName }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Anticipated Recipient Account Name
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.anticipatedDisbursementDetails.anticipatedBankAccountName }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Disbursed Account Name
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.disbursementDetails.disbursedAccountName }}
          </span>
        </div>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            First Repayment Date
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{
              data.anticipatedDisbursementDetails.firstRepaymentDate | moment
            }}
          </span>
        </div>
        <div class="col-6">
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Disbursed Amount
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ data.disbursementDetails.disbursedAmount | currency }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-6" v-if="data.loanProductFees.length">
      <div class="account_item_head">
        <p class="account_item_head_text text--white">Loan Fees</p>
      </div>
      <div class="row no-gutters account_item align-items-center">
        <div
          class="col-6"
          v-for="(fees, index) in data.loanProductFees"
          :key="index"
        >
          <span
            class="text--black text--opaq2 text--small text--bold account_item_title"
          >
            Fee name: {{ fees.feeName }}
          </span>
          <span
            class="text--black text--opaq2 text--small account_item_title"
          >
            Fee Type: {{ fees.feeType }}
          </span>
          <span
            class="text--black text--opaq2 text--small account_item_title"
          >
            Fee Payment: {{ fees.feePayment }}
          </span>
          <span
            class="text--black text--opaq2 text--small account_item_title"
          >
            Required: {{ fees.isRequired }}
          </span>
          <span class="text--black text--regular account_item_subtitle overlap">
            {{ fees.feeAmount | currency }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  props: {
    data: Object,
    enums: Object,
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  methods: {
    repaymentAreMadeEvery(repaymentPeriodUnit) {
      const unit = this.enums.repaymentAreMadeEvery.find(
        (unit) => unit.id == Number(repaymentPeriodUnit)
      );
      if (unit) {
        return unit.name.slice(0, -1);
      } else {
        return "";
      }
    },
    howIsTheInterestRateCharged(interestRate) {
      const unit = this.enums.howIsTheInterestRateCharged.find(
        (unit) => unit.id == interestRate
      );
      if (unit) {
        return unit.name.slice(0, -1);
      } else {
        return "";
      }
    },
    interestCalculationMethod(method) {
      const unit = this.enums.interestCalculationMethod.find(
        (unit) => unit.id == method
      );
      if (unit) {
        return unit.name;
      } else {
        return "_";
      }
    },
    accruedInterestPostingFrequency(method) {
      const unit = this.enums.accruedInterestPostingFrequency.find(
        (unit) => unit.id == method
      );
      if (unit) {
        return unit.name;
      } else {
        return "";
      }
    },
    totalPaid(d) {
      let t = 0;
      t += d.feesPaid;
      t += d.interestPaid;
      t += d.penaltyPaid;
      t += d.principalPaid;
      t += d.interestFromArrearsPaid;
      return t;
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      if (isNaN(value)) {
        return "_";
      }
      return "₦" + val;
    },
  },
  computed: {
    loanInterestType() {
      try {
        const hh = this.enums.repaymentAreMadeEvery.find(
          (h) => h.id == this.data.adminLoanProduct.repayment_schedule_method
        );
        return hh.name;
      } catch (e) {
        return "";
      }
    },
    RequireOfferAcceptance() {
      const { require_offer_acceptance } = this.data.adminLoanProduct;
      if (require_offer_acceptance === null) return false;
      return require_offer_acceptance;
    },
    AllowUnderwriterOveride() {
      const { allow_underwriter_overide } = this.data.adminLoanProduct;
      if (allow_underwriter_overide === null) return false;
      return allow_underwriter_overide;
    },
    isOVerRide() {
      return this.data?.overrideStatus.toLowerCase() === "yes";
    },
    interestRateDisplay() {
      const unit = this.enums.howIsTheInterestRateCharged.find(
        (unit) => unit.id == this.data.adminLoanProduct.interest_type
      );
      if (unit) {
        return unit.name;
      } else {
        return "_";
      }
    },
    prepaymentAcceptance() {
      try {
        const hh = this.enums.prepaymentAcceptance.find(
          (h) => h.id == this.data.adminLoanProduct.prepayment_acceptance
        );
        return hh.name;
      } catch (e) {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
