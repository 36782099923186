<template>
  <div
    class="tab-pane fade"
    id="pills-activities"
    role="tabpanel"
    aria-labelledby="pills-activities-tab"
  >
    <div class="pt-3">
      <div
        class="d-flex align-items-center justify-content-center p5"
        v-if="loading"
      >
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span class="text--black text--600 text--medium d-inline-flex ml-4"
          >Please wait</span
        >
      </div>
      <div class="form__item" v-for="(item, i) in data" :key="i" v-else>
        <div class="row">
          <div class="col-1">
            <img src="../../../../../assets/img/login.svg" alt="" srcset="" />
          </div>
          <div class="col-11">
            <p>
              <!-- <span
                class="d-inline-block text--black text--regular-alt text--600"
                >{{ item.name }}</span
              > -->
              &nbsp;<span
                class="d-inline-block text--regular text--dark text--opaq1 text--capital text-300"
                >{{ item.event }}</span
              >
            </p>
            <span class="text--tiny text--dark text-300 text--opaq1">
              {{ item.time | humanDate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "accountActivities",
  props: {
    data: Array,
    enums: Object,
    loading: Boolean,
  },
  filters: {
    humanDate: function (date) {
      return date != null ? moment(date).fromNow() : "";
    },
  },
};
</script>
