<template>
  <div
    v-if="show"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="viewTransactionModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editSecurity"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Loan Transaction {{ transaction && transaction.id }}
          </h5>
        </div>
        <div class="modal__body">
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Type:</h3>
              <h3 class="text--capital">
                {{ transaction && transaction.type.toLowerCase() }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Creation Date:</h3>
              <h3 class="text--capital">
                {{
                  transaction && transaction.creationDate | moment("DD/MM/YYYY")
                }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Value Date(Entry Date):</h3>
              <h3 class="text--capital">
                {{
                  transaction && transaction.valueDate | moment("DD/MM/YYYY")
                }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Amount:</h3>
              <h3 class="text--capital">
                {{ transaction && toNaira(transaction.amount) }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Total Balance:</h3>
              <h3 class="text--capital">
                {{ transaction && toNaira(transaction.totalBalance) }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Interest Amount:</h3>
              <h3 class="text--capital">
                {{ transaction && toNaira(transaction.interestAmount) }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Principal Balance:</h3>
              <h3 class="text--capital">
                {{ transaction && toNaira(transaction.principalBalance) }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">User:</h3>
              <h3 class="text--capital">
                {{ transaction && transaction.user ? transaction.user : "NA" }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Branch Name:</h3>
              <h3 class="text--capital">
                {{
                  transaction && transaction.branchName
                    ? transaction.branchName
                    : "NA"
                }}
              </h3>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <h3 class="pr-5">Account Name:</h3>
              <h3 class="text--capital">
                {{
                  transaction && transaction.accountName
                    ? transaction.accountName
                    : "NA"
                }}
              </h3>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "viewTransactionModal",
  props: {
    account: Object,
    transaction: Object,
    show: Boolean,
  },
  data: function () {
    return {
      notes: "",
      confirm: false,
      process: false,
      loanAccount: {},
    };
  },
  methods: {
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      if (isNaN(value)) {
        return "_";
      }
      return "₦" + val;
    },
  },
};
</script>
