<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="editCommentModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Add Comment
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editCommentModal"
              class="modal__close modal__close--black"
              ref="close"
              @click="close()"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit Comment</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="mb5">
              <div class="form__item">
                <label class="form__label text--regular text--black"
                  >Comment</label
                >
                <div class="error--text" v-if="!uiProps.errors.comment.valid">
                  {{ uiProps.errors.comment.message }}
                </div>
                <textarea
                  cols="5"
                  rows="3"
                  class="input form__input"
                  :class="{ 'input-error': !uiProps.errors.comment.valid }"
                  placeholder="Enter comment"
                  v-model="form.comment"
                ></textarea>
              </div>
            </div>
            <div class="form__item">
              <div class="form__action">
                <button
                  type="button"
                  class="button form__button form__button--lg save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Processing..." : "Save Comment" }}
                </button>
                <a
                  role="button"
                  @click="close()"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
function initialState() {
  return {
    form: { comment: "" },
    uiProps: {
      errors: {
        comment: { valid: true, message: "" },
      },
    },
    confirm: false,
    process: false,
  };
}
export default {
  props: {
    account: Object,
    comment: Object,
    show: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    save: function () {
      this.process = true;
      const p = {
        commentNote: this.form.comment,
        // userKey: "",
        commentKey: this.comment.commentKey,
        // loanKey: this.account.id,
      };
      LoanApiService.put("LoanAccount/edit-loanaccount-comment", p)
        .then((response) => {
          this.confirm = false;
          this.process = false;
          this.$emit("refresh");
          this.$emit("reload-activities");
          this.$emit("alertSuccess", response.data.message);
          this.form.comment = "";
          this.$refs.close.click();
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          this.$emit("alertError", e.response.data.message);
        });
    },
    validate: function () {
      if (this.form.comment.length < 5) {
        this.uiProps.errors.comment.valid = false;
        this.uiProps.errors.comment.message =
          "Comment cannot be less than 5 characters";
        return false;
      } else {
        return true;
      }
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {
    comment(change) {
      this.form.comment = change.commentNote;
    },
  },
};
</script>
