<template>
  <thead>
    <tr>
      <th scope="col" class="table__header__text table__text text--capital">
        #
      </th>
      <th
        scope="col"
        class="table__header__text table__text text--capital text--left"
      >
        Due
      </th>

      <!--EXPECTED SECTION-->
      <template v-if="amountExpected === true">
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__left"
        >
          Principal Expected
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Interest Expected
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Fees Expected
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Penalty Expected
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__right"
        >
          Total Expected
        </th>
      </template>

      <!--EXPECTED SECTION-->
      <template v-if="amountPaid === true">
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__left"
        >
          Principal Paid
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Interest Paid
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Fees Paid
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Penalty Paid
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Paid Date
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__right"
        >
          Total paid
        </th>
      </template>

      <!--EXPECTED SECTION-->
      <template v-if="amountDue === true">
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__left"
        >
          Principal Due
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Interest Due
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Fees Due
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small"
        >
          Penalty Due
        </th>
        <th
          scope="col"
          class="table__header__text table__text text--capital text--small table__amount__right"
        >
          Total Due
        </th>
      </template>

      <th
        scope="col"
        class="table__header__text table__text text--capital text--small table__amount__left"
      >
        State
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "schedulesTableHead",
  props: {
    amountDue: Boolean,
    amountPaid: Boolean,
    amountExpected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.table__amount {
  &__right {
    border-right: 2px solid rgba(224, 224, 224, 0.8);
    padding-right: 10px !important;

    & span {
      padding-right: 10px !important;
    }
  }

  &__left {
    border-left: 2px solid rgba(224, 224, 224, 0.8);
    padding-left: 10px !important;

    & span {
      padding-left: 10px !important;
    }
  }
}
</style>
