<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Repayment of Loan Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  :disabled="process"
                  @click.prevent="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                ref="close"
                @click="closeRepayment"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Enter Repayment</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Amount</label>
                    <input
                      type="text"
                      class="input input--right form__input form__input--lg"
                      :class="{
                        'input-required':
                          formError.amount ||
                          numberIsValid(formattedAmountPaid),
                      }"
                      :value="formattedAmountPaid"
                      @input="numberIsValid(formattedAmountPaid)"
                      @blur="fomartAndValidateDepositAmount($event)"
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Select Currency</label>
                    <select class="select select--lg" required>
                      <option value="NGN" selected>₦ NGN</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">Value Date (Entry Date)</label>
                <input
                  type="date"
                  v-model="form.valueDate"
                  class="input input--right form__input form__input--lg"
                  :class="{
                    'input-required': formError.valueDate,
                  }"
                />
              </div>
              <!-- <div class="form__item">
                <label class="form__label">Select Channel</label>
                <select
                  class="select select--lg"
                  required
                  v-model="form.channelKey"
                >
                  <option value="null" disabled selected>
                    {{ loadingAccounts ? "Loading..." : "Select Channel" }}
                  </option>
                  <option
                    v-for="item in accounts"
                    :key="item.id"
                    v-bind:value="item.id"
                  >
                    {{ item.productName }}
                  </option>
                </select>
              </div> -->
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >notes</label
                  >
                  <textarea
                    cols="5"
                    rows="3"
                    class="input form__input"
                    placeholder=""
                    v-model="form.notes"
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#enterRepayment"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    @click="confirmSave()"
                  >
                    Apply Repayment
                  </button>
                  <a
                    data-dismiss="#enterRepayment"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeRepayment"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
import DepositApiService from "@/core/services/api.service";
import { mapState } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";
export default {
  mixins: [currencyMixin],
  props: {
    show: Boolean,
  },
  data: function () {
    return {
      form: {
        amount: null,
        valueDate: null,
        channelKey: null,
        currency: "NGN",
        notes: "",
      },
      formError: {
        amount: null,
        valueDate: null,
        currency: "NGN",
      },
      confirm: false,
      process: false,
      loadingAccounts: false,
      accounts: [],
      formattedAmountPaid: "",
    };
  },
  methods: {
    closeRepayment() {
      this.$emit("close-repayment");
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    fomartAndValidateDepositAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.amount = amtPaid;
        this.formattedAmountPaid = amountInputted;
        return;
      }
      this.form.amount = this.form.amount;
      this.formattedAmountPaid = "";
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    save: function () {
      if (this.validateRepaymentForm()) {
        this.process = true;
        const p = JSON.parse(JSON.stringify(this.form));
        p.loanAccountKey = this.account_details.id;
        LoanApiService.post("LoanAccount/create-loan-repayment", p)
          .then((res) => res.data)
          .then((res) => {
            this.confirm = false;
            this.process = false;
            this.$emit("refresh");
            this.$emit("get-details");
            this.$emit("get-schedule");
            this.form = {
              amount: null,
              valueDate: null,
              channelKey: null,
              currency: "NGN",
              notes: "",
            };
            this.formattedAmountPaid = "";
            this.$refs.close.click();

            this.$emit(
              "alertSuccess",
              res.message ? res.message : "Repayment Entered Successfully"
            );
          })
          .catch((e) => {
            this.confirm = false;
            try {
              this.process = false;
              this.$emit("alertError", e.response.data.message);
            } catch (e) {
              this.$emit(
                "alertError",
                "An unexpected Error occurred: Kindly confirm you are connected to the internet"
              );
            }
          });
      } else {
        this.confirm = false;
        this.$emit("alertWarning", "Some form fields are required.");
      }
    },
    getAccounts: function () {
      this.loadingAccounts = true;
      DepositApiService.get(
        "DepositAdministration/GetDepositProductSettlementAccount"
      )
        .then((res) => res.data)
        .then((res) => {
          this.loadingAccounts = false;
          this.accounts = res;
        })
        .catch((e) => {
          this.loadingAccounts = false;
          try {
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validateRepaymentForm() {
      this.clearRepaymentErrorForm();
      if (this.form.amount && this.form.valueDate && this.form.currency) {
        return true;
      }

      if (!this.form.amount) {
        this.formError.amount = true;
      }
      if (!this.form.valueDate) {
        this.formError.valueDate = true;
      }
      if (!this.form.currency) {
        this.formError.currency = true;
      }

      return false;
    },
    clearRepaymentErrorForm() {
      this.formError = {
        amount: false,
        valueDate: false,
        currency: false,
      };
    },
  },
  computed: mapState({
    account_details: (state) =>
      state.loan.account_details ? state.loan.account_details.data : null,
  }),
  mounted() {
    this.$watch("show", function (value) {
      if (value) this.getAccounts();
    });
  },
};
</script>
