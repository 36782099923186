<template>
  <div
    class="tab-pane fade"
    id="pills-transactions"
    role="tabpanel"
    aria-labelledby="pills-transactions-tab"
  >
    <div class="d-flex justify-content-between py-5">
      <label
        for="showadjusted"
        class="d-block has-checkbox text--black text--500 text--regular"
        >Show Adjusted Transactions
        <input
          type="checkbox"
          id="showadjusted"
          class="form-"
          @click="changeTransactionStatus"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 17%"
          >
            User
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 12%"
          >
            ID
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Value Date (Entry Date)
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Type
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 12%"
          >
            Amount
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 12%"
          >
            Total Balance
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.transactionId">
          <td>
            <a
              href="customer-profile.html"
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital text--primary',
              ]"
            >
              {{ item.user ? item.user : "NA" }}</a
            >
          </td>
          <td>
            <span
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital',
              ]"
              >{{ item.transactionId }}</span
            >
          </td>
          <td>
            <span
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital',
              ]"
              >{{ item.valueDate | moment("DD/MM/YYYY") }}</span
            >
          </td>
          <td>
            <span
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital pr-3',
              ]"
              >{{ item.type }}</span
            >
          </td>
          <td>
            <span
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital',
              ]"
              >{{ toNaira(item.amount) }}</span
            >
          </td>
          <td>
            <span
              :class="[
                item.isReversed ? 'text--opacity' : '',
                'table__body__text table__text text--capital',
              ]"
              >{{ toNaira(item.totalBalance) }}</span
            >
          </td>
          <td style="padding-right: 0 !important">
            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                action
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item text--capital" @click="view(item)"
                  >View</a
                >
                <a v-if="!item.isReversed" class="dropdown-item text--capital" @click="edit(item)"
                  >Adjust</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    data: Array,
    enums: Object,
  },
  data() {
    return {
      transactionStatus: false,
    };
  },
  methods: {
    changeTransactionStatus() {
      this.transactionStatus = !this.transactionStatus;
      this.$emit("show-adjusted-transactions", this.transactionStatus);
    },
    edit(transaction) {
      this.$emit("edit", transaction);
    },
    view(transaction) {
      this.$emit("view", transaction);
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      if (isNaN(value)) {
        return "_";
      }
      return "₦" + val;
    },
  },
};
</script>
<style lang="scss" scoped>
.text--opacity {
  opacity: 0.5;
}
</style>
