<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Edit Penalty Rate
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a class="modal__close modal__close--black" @click="closeModal">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit Penalty Rate</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="mt-3">
                <div class="row py-3 align-items-center">
                  <div class="col-6">
                    <span
                      class="text--black text--regular d-block text--500 mb-3 text--capital"
                    >
                      Display Name
                    </span>
                    <span
                      class="text--black text--medium d-block text--600 text--capital"
                    >
                      {{ account && account.loanName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="row py-3 align-items-center">
                  <div class="col-12">
                    <span
                      class="text--black text--regular d-block text--500 mb-3 text--capital"
                    >
                      Current Rate
                    </span>
                    <span
                      class="text--black text--medium d-block text--600 text--capital"
                    >
                      {{ account && account.penaltyRate }}%
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3 mb5">
                <div class="row py-3 align-items-center">
                  <div class="col-12">
                    <div class="form__item">
                      <label class="form__label">New Rate</label>
                      <input
                        type="number"
                        v-model.number="rate"
                        min="0"
                        class="input input--right form__input form__input--lg"
                        placeholder="Enter Rate"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#editSecurity"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    @click="confirmSave()"
                  >
                    Apply Changes
                  </button>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import LoanApiService from "@/core/services/api.loan.service";

export default {
  name: "EditPenaltyRateModal",
  props: {
    account: Object,
    show: Boolean,
  },
  data: function () {
    return {
      rate: "",
      confirm: false,
      process: false,
      loanAccount: {},
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-penalty-modal");
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    save: function () {
      this.process = true;
      const p = {
        loanKey: this.account.id,
        loanPenaltyRate: this.rate,
      };
      LoanApiService.patch("LoanAccount/edit-loan-penalty-rate", p)
        .then((response) => {
          if (response.data.status !== false) {
            this.confirm = false;
            this.process = false;
            this.rate = "";
            this.$emit("refresh");
            this.$emit("get-transactions");
            this.$emit("get-activities");
            this.$emit("alertSuccess", response.data.message);
            this.closeModal();
          } else {
            this.$emit("alertError", response.data.message);
            this.process = false;
          }
        })
        .catch((error) => {
          this.$emit("alertError", error.response.data.message);
          this.process = false;
        });
    },
  },
};
</script>
