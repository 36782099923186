var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt5"},_vm._l((_vm.data),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userCanViewLoanAccount),expression:"userCanViewLoanAccount"}],key:item.fieldSetKey,staticClass:"col-6"},[_c('div',{staticClass:"account_item_head"},[_c('p',{staticClass:"account_item_head_text text--white"},[_vm._v(" "+_vm._s(item.fieldSetName)+" ")])]),_c('div',{staticClass:"row no-gutters account_item align-items-start"},_vm._l((item.fields),function(item2){return _c('div',{key:item2.fieldKey,staticClass:"col-6 p-4"},[_c('span',{staticClass:"text--black text--opaq2 text--small text--bold account_item_title"},[_vm._v(" "+_vm._s(item2.fieldName)+" "),(_vm.userCanEditLoanAccount)?_c('a',{staticClass:"text--primary text--capital account_item_head_text mr-3",on:{"click":function($event){return _vm.editField(
                item.fieldSetKey,
                item2.fieldKey,
                item2.value,
                item.fields
              )}}},[_vm._v("edit")]):_vm._e()]),(
            ['customer link', 'group link', 'user link'].includes(
              item2.dataType.toLowerCase()
            )
          )?_c('span',{staticClass:"table__body__text table__text text--capital text--primary cursor account_item_subtitle",staticStyle:{"cursor":"pointer","word-wrap":"break-word"},on:{"click":function($event){return _vm.routeToLinkView(item2.value, item2.dataType)}}},[_vm._v(" "+_vm._s(item2.value && item2.value.length ? item2.value.split("-").at(-1) : "")+" ")]):_c('span',{staticClass:"text--black text--regular account_item_subtitle",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(item2.value)+" ")])])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }