<template>
  <div
    v-if="show"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="editTransactionModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                data-dismiss="#confirmModal"
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                ref="close"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Adjust {{ transaction && transaction.type.toLowerCase() }}
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editSecurity"
              ref="close"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Adjust {{ transaction && transaction.type.toLowerCase() }}
          </h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="mt-3 mb5">
              <div class="row py-3 align-items-center">
                <div class="col-12">
                  <div class="form__item">
                    <label class="form__label">Adjustment Reason</label>
                    <textarea
                      v-model="notes"
                      cols="5"
                      rows="2"
                      class="input form__input"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#editSecurity"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave()"
                >
                  Adjust {{ transaction && transaction.type.toLowerCase() }}
                </button>
                <a
                  data-dismiss="#editSecurity"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoanApiService from "@/core/services/api.loan.service";

export default {
  name: "editTransactionModalModal",
  props: {
    account: Object,
    transaction: Object,
    show: Boolean,
  },
  data: function () {
    return {
      notes: "",
      confirm: false,
      process: false,
      loanAccount: {},
    };
  },
  methods: {
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    save: function () {
      this.process = true;
      const p = {
        loanAccountKey: this.account.id,
        originalTransactionId: this.transaction.transactionKey,
        notes: this.notes,
      };

      if (
        this.transaction &&
        this.transaction.type.toLowerCase() === "interest applied"
      ) {
        LoanApiService.post(`LoanAccount/adjust-interest-applied`, p)
          .then((response) => {
            if (response.data.status !== false) {
              this.confirm = false;
              this.process = false;
              this.rate = "";
              this.$emit("refresh");
              this.$emit("reload-account");
              this.$emit("reload-schedule");
              this.$emit("alertSuccess", response.data.message);
              this.$refs.close.click();
            } else {
              this.$emit("alertError", response.data.message);
              this.process = false;
            }
          })
          .catch((error) => {
            this.$emit("alertError", error.response.data.message);
            this.process = false;
          });
      } else {
        LoanApiService.put(
          `LoanTransaction/adjusttransaction/${p.originalTransactionId}/${p.loanAccountKey}/${p.notes}`
        )
          .then((response) => {
            if (response.data.status !== false) {
              this.confirm = false;
              this.process = false;
              this.rate = "";
              this.$emit("refresh");
              this.$emit("reload-account");
              this.$emit("reload-schedule");
              this.$emit("alertSuccess", response.data.message);
              this.$refs.close.click();
            } else {
              this.$emit("alertError", response.data.message);
              this.process = false;
            }
          })
          .catch((error) => {
            this.$emit("alertError", error.response.data.message);
            this.process = false;
          });
      }
    },
  },
};
</script>
