<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="applyFeesModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Apply Fee
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process || reconfirm ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              ref="close"
              class="modal__close modal__close--black"
              @click="closeApplyFee"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Apply Fees</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="form__item">
              <label class="form__label">Fee</label>
              <select
                class="select select--lg"
                required
                v-model="selectedFee"
                @change="popAmountFee"
              >
                <option value="" disabled selected>Select Fee</option>
                <option v-for="fee in productFee" :key="fee.id" :value="fee">
                  {{ fee.name }}
                </option>
              </select>
              <!--              <select-->
              <!--                v-model="theInput.feeTypeId"-->
              <!--                class="select select&#45;&#45;lg"-->
              <!--                required-->
              <!--              >-->
              <!--                <option value="" disabled selected>Select Field Set</option>-->
              <!--                <option value="Accured Interest">Accrued Interest</option>-->
              <!--                <option value="Pay off fee">Pay off fee</option>-->
              <!--                <option value="Admin fee (Manual flat capitalized)">-->
              <!--                  Admin fee (Manual flat capitalized)-->
              <!--                </option>-->
              <!--              </select>-->
            </div>

            <div class="form__item">
              <label class="form__label">Fee Amount</label>
              <div class="error--text" v-if="!uiProps.errors.fee.valid">
                {{ uiProps.errors.fee.message }}
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="w-100 d-flex text--medium text-white text--600 align-items-center justify-content-center"
                  >
                    ₦
                  </span>
                </div>
                <input
                  type="text"
                  :value="amount"
                  :disabled="disableField(amount)"
                  class="input input--right form__input form__input--lg"
                  placeholder="Enter Amount"
                  @input="numberIsValid(amount)"
                  @blur="formatAndValidateAmount($event)"
                />
              </div>
            </div>
            <div class="form__item">
              <label class="form__label">Due On</label>
              <div class="error--text" v-if="!uiProps.errors.due.valid">
                {{ uiProps.errors.due.message }}
              </div>
              <select
                class="select select--lg"
                v-model="theInput.feePaymentType"
                required
              >
                <option value="null" disabled selected>
                  Select Due Period
                </option>
                <option value="0">Next Repayment</option>
                <option value="1">Last Repayment</option>
                <option value="2">Other</option>
              </select>
            </div>
            <div class="form__item" v-if="showLoanSchedule">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Due Date</th>
                    <th scope="col">Principal</th>
                    <th scope="col">Interest</th>
                    <th scope="col">Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(schedule, index) in loanAccountRepaymentSchedule"
                    :key="index"
                  >
                    <th>{{ schedule.dueDate }}</th>
                    <td>{{ schedule.principalPaid }}</td>
                    <td>{{ schedule.interestPaid }}</td>
                    <td>
                      <input
                        v-model="schedule.amountInputed"
                        type="number"
                        value="0.00"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form__item">
              <label
                class="form__label text--regular-alt text--bold text--black"
                >notes</label
              >
              <textarea
                cols="5"
                rows="5"
                v-model="theInput.notes"
                class="input form__input"
              ></textarea>
            </div>
            <div class="form__item">
              <div class="form__action">
                <input
                  @click="confirmSave"
                  type="button"
                  value="apply fee"
                  class="button form__button form__button--lg w-30 save-changes"
                />

                <a
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeApplyFee"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--RECONFIRM DIALOG-->
    <div
      class="modal modal__confirm"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="'none'"
      aria-hidden="true"
    >
      <!-- v-bind:style="{ display: reconfirm ? 'block' : 'none' }" -->
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="close()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Apply Fee
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                {{ canProceedMsg }}
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="close"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="confirmApply"
                >
                  {{ reconfirmation ? "Saving..." : "Ok" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  props: ["loanid", "presentState", "accruedInterest", "show"],
  mixins: [currencyMixin],
  data() {
    return {
      theInput: {
        notes: "",
        fee: "null",
        feeTypeId: "",
        loanAccountKey: this.$route.params.loanAccountKey,
        feePaymentType: "null",
        rescheduleFee: [],
      },
      loanAccountRepaymentSchedule: {},
      showLoanSchedule: "",
      amount: "",
      scheduleAmountInputed: "",
      uiProps: {
        errors: {
          fee: { valid: true, message: "" },
          due: { valid: true, message: "" },
        },
      },
      confirm: false,
      process: false,
      productFee: [],
      selectedFee: {},
      canProceedToApplyFees: false,
      canProceedMsg: "",
      reconfirm: false,
      reconfirmation: false,
    };
  },

  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.getLoanRepaymentSchedule();
        this.getLoanFee();
      }
    });
  },

  methods: {
    closeApplyFee() {
      this.$emit("close-apply-fee");
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    formatAndValidateAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.theInput.fee = amtPaid;
        this.amount = amountInputted;
        return;
      }
      this.theInput.fee = this.theInput.fee;
      this.amount = "";
    },
    popAmountFee() {
      this.theInput.fee = this.selectedFee.amount;
      this.theInput.feeTypeId = this.selectedFee.id;
      this.amount = this.inputCurrencyFormat(this.selectedFee.amount);
    },
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    disableField(amount) {
      if (amount > 0) return true;
      return false;
    },
    resetErrors() {
      this.uiProps.errors = {
        fee: { valid: true, message: "" },
        due: { valid: true, message: "" },
      };
    },
    validate: function () {
      if (this.theInput.fee === "null") {
        this.uiProps.errors.fee.valid = false;
        this.uiProps.errors.fee.message = "Fee is required";
        return false;
      } else if (this.theInput.feePaymentType === "null") {
        this.uiProps.errors.due.valid = false;
        this.uiProps.errors.due.message = "Fee Payment Type is required";
        return false;
      } else {
        return true;
      }
    },
    applyTheAccuredInterest() {
      LoanApiService.patch("LoanAccount/applyfeetoloanshedule", this.theInput)
        .then((response) => {
          // if (response.status == 200) {
          this.process = false;
          this.theInput = {
            notes: "",
            fee: "null",
            feeTypeId: "",
            loanAccountKey: this.$route.params.loanAccountKey,
            feePaymentType: "null",
            rescheduleFee: [],
          };
          this.$emit("refresh");
          this.$emit("alertSuccess", response.message);
          // } else {
          //   this.$emit("alertError", response.message);
          // }
        })
        .catch((error) => {
          this.process = false;
          try {
            this.$emit("alertError", error.response.data.message);
          } catch (error) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },

    getLoanRepaymentSchedule() {
      LoanApiService.get(
        `LoanAccount/getloanaccountrepaymentschedule/${this.$route.params.loanAccountKey}`
      )
        .then((response) => {
          this.loanAccountRepaymentSchedule =
            response.data.data.generalRepayment.map((i) => ({
              ...i,
              amountInputed: "",
            }));
        })
        .catch((error) => {
          this.errors = error.response;
        });
    },

    close() {
      this.reconfirm = false;
      this.process = false;
      this.confirm = false;
    },

    save() {
      this.process = true;
      let modifiedSchedules = this.loanAccountRepaymentSchedule
        .filter((schedule) => schedule.amountInputed !== "")
        .map((schedule) => ({
          amount: parseInt(schedule.amountInputed),
          scheduleId: schedule.repaymentKey,
        }));
      this.theInput.rescheduleFee = [...modifiedSchedules];

      //check the loan account cap charges before applying fee
      this.checkLoanCapCharges();
    },

    confirmApply() {
      this.reconfirmation = true;
      LoanApiService.patch("LoanAccount/applyfeetoloanshedule", this.theInput)
        .then((response) => {
          this.process = false;
          // if (response.status == 200) {
          this.theInput = {
            notes: "",
            fee: "null",
            feeTypeId: "",
            loanAccountKey: this.$route.params.loanAccountKey,
            feePaymentType: "null",
            rescheduleFee: [],
          };
          this.selectedFee= {};
          this.amount = "";
          this.$emit("refresh");
          this.$emit("get-transactions");
          this.$emit("alertSuccess", response.data.message);
          this.$refs.close.click();
          this.reconfirmation = false;
          this.canProceedToApplyFees = false;

          this.reconfirm = false;
          this.process = false;
          this.confirm = false;
          // } else {
          //   this.$emit("alertError", response.message);
          // }
        })
        .catch((error) => {
          this.process = false;
          this.confirm = false;
          try {
            this.$emit("alertError", error.response.data.message);
          } catch (error) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },

    getLoanFee() {
      const loanAccountKey = this.$route.params.loanAccountKey;

      LoanApiService.get(
        `LoanAccount/get-manual_loan-fee/${loanAccountKey}`
      ).then((resp) => {
        this.productFee = resp.data.data;
      });
    },

    checkLoanCapCharges() {
      const loanAccountKey = this.$route.params.loanAccountKey;

      const amount = this.theInput.fee;

      LoanApiService.get(
        `LoanAccount/checkCapChargesBeforeFeeApply?fee=${amount}&loanKey=${loanAccountKey}&type=fee`
      ).then((resp) => {
        this.canProceedToApplyFees = resp.data.status;
        this.canProceedMsg = resp.data.message;

        this.confirmApply();
      });
    },
  },

  watch: {
    "theInput.feePaymentType": function (newVal) {
      if (newVal === "2") {
        this.showLoanSchedule = 1;
      } else {
        this.showLoanSchedule = "";
      }
    },
    // canProceedToApplyFees: function (newVal) {
    //   if (newVal) {
    //     return this.confirmApply();
    //   }
    //   this.reconfirm = false;
    //   this.process = false;
    // },
  },
};
</script>
