<template>
  <div
    class="tab-pane fade"
    id="pills-schedule"
    role="tabpanel"
    aria-labelledby="pills-schedule-tab"
  >
    <div class="d-flex justify-content-between py-5 label-des" v-if="!loading">
      <label
        for="paidrepayment"
        class="d-block has-checkbox text--black text--500 text--regular"
        >Paid Repayment
        <input
          type="checkbox"
          id="paidrepayment"
          class="form-"
          v-bind:aria-checked="paidRepayment"
          v-bind:checked="paidRepayment"
          v-model="paidRepayment"
        />
        <span class="checkmark"></span>
      </label>
      <!--      <label-->
      <!--        for="emptyrepayment"-->
      <!--        class="d-block has-checkbox text&#45;&#45;black text&#45;&#45;500 text&#45;&#45;regular"-->
      <!--        >Empty Repayment-->
      <!--        <input-->
      <!--          type="checkbox"-->
      <!--          id="emptyrepayment"-->
      <!--          class="form-"-->
      <!--          v-bind:aria-checked="emptyRepayment"-->
      <!--          v-bind:checked="emptyRepayment"-->
      <!--          v-model="emptyRepayment"-->
      <!--        />-->
      <!--        <span class="checkmark"></span>-->
      <!--      </label>-->
      <label
        for="amtexpected"
        class="d-block has-checkbox text--black text--500 text--regular"
        >Amount Expected
        <input
          type="checkbox"
          id="amtexpected"
          class="form-"
          v-bind:aria-checked="amountExpected"
          v-bind:checked="amountExpected"
          v-model="amountExpected"
        />
        <span class="checkmark"></span>
      </label>
      <label
        for="amtpaid"
        class="d-block has-checkbox text--black text--500 text--regular"
        >Amount Paid
        <input
          type="checkbox"
          id="amtpaid"
          class="form-"
          v-bind:aria-checked="amountPaid"
          v-bind:checked="amountPaid"
          v-model="amountPaid"
        />
        <span class="checkmark"></span>
      </label>
      <label
        for="amtdue"
        class="d-block has-checkbox text--black text--500 text--regular"
        >Amount Due
        <input
          type="checkbox"
          id="amtdue"
          class="form-"
          v-bind:aria-checked="amountDue"
          v-bind:checked="amountDue"
          v-model="amountDue"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="loading"
    >
      <div
        class="spinner-border"
        style="width: 1rem; height: 1rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--300 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>
    <hr />
    <div class="mt5 w-100" style="overflow-x: scroll">
      <table class="customtable _customtable">
        <template v-if="data && data.length > 0">
          <SchedulesTableHead
            :amountExpected="amountExpected"
            :amountPaid="amountPaid"
            :amountDue="amountDue"
          />
        </template>
        <tbody v-show="data">
          <tr v-for="(item, i) in data" :key="i">
            <template v-if="show(item.state)">
              <td class="" style="padding-right: 10px !important">
                <span class="table__body__text table__text text--capital p-2">{{
                  item.installment
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  item.due | moment("DD/MM/YYYY")
                }}</span>
              </td>
              <!--EXPECTED SECTION-->
              <template v-if="amountExpected == true">
                <td class="table__amount__left">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.expectedPrincipal)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.interestExpected)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.feeExpected)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.penaltyExpected)
                  }}</span>
                </td>
                <td class="table__amount__right">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.totalExpected)
                  }}</span>
                </td>
              </template>

              <!--PAID SECTION-->
              <template v-if="amountPaid == true">
                <td class="table__amount__left">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.principalPaid)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.interestPaid)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    item.feesPaid
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.penaltyPaid)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    item.paidDate | moment("DD/MM/YYYY")
                  }}</span>
                </td>
                <td class="table__amount__right">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.totalPaid)
                  }}</span>
                </td>
              </template>
              <!--PAID SECTION-->
              <template v-if="amountDue == true">
                <td class="table__amount__left">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.principalDue)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.interestDue)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.feesDue)
                  }}</span>
                </td>
                <td class="">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.penaltyDue)
                  }}</span>
                </td>
                <td class="table__amount__right">
                  <span class="table__body__text table__text text--capital">{{
                    toNaira(item.totalDue)
                  }}</span>
                </td>
              </template>
              <td class="table__amount__left">
                <span class="table__body__text table__text text--capital">{{
                  item.state
                }}</span>
              </td>
            </template>
          </tr>
          <tr>
            <td class="">
              <span class="table__body__text table__text text--capital">N</span>
            </td>
            <td class="">
              <span class="table__body__text table__text text--capital"
                >Total</span
              >
            </td>
            <template v-if="amountExpected">
              <td class="table__amount__left">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalExpectedAmt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalExpectedInt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalExpectedFee(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalExpectedPen(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalExpectedSum(data))
                }}</span>
              </td>
            </template>
            <template v-if="amountPaid">
              <td class="table__amount__left">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalPaidAmt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalPaidInt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalPaidFee(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalPaidPen(data))
                }}</span>
              </td>
              <td class="">
                <span
                  class="table__body__text table__text text--capital"
                ></span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalPaidSum(data))
                }}</span>
              </td>
            </template>
            <template v-if="amountDue">
              <td class="table__amount__left">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalDueAmt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalDueInt(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalDueFee(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalDuePen(data))
                }}</span>
              </td>
              <td class="">
                <span class="table__body__text table__text text--capital">{{
                  toNaira(totalDueSum(data))
                }}</span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SchedulesTableHead from "./molecules/schedulesTableHead";
export default {
  name: "accountSchedules",
  components: {
    SchedulesTableHead: SchedulesTableHead,
  },
  props: {
    data: Array,
    enums: Object,
    loading: Boolean,
  },
  data: function () {
    return {
      paidRepayment: true,
      // emptyRepayment: true,
      amountExpected: true,
      amountPaid: true,
      amountDue: true,
    };
  },
  methods: {
    show(status) {
      if (status === "Paid" && this.paidRepayment) {
        return true;
      }
      if (status === "Paid" && !this.paidRepayment) {
        return false;
      } else {
        return true;
      }
    },
    totalExpectedAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.expectedPrincipal;
      }, 0);
      return sum;
    },
    totalExpectedInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestExpected;
      }, 0);
      return sum;
    },
    totalExpectedFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feeExpected;
      }, 0);
      return sum;
    },
    totalExpectedPen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyExpected;
      }, 0);
      return sum;
    },
    totalExpectedSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalExpected;
      }, 0);
      return sum;
    },

    //Amount paid
    totalPaidAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.principalPaid;
      }, 0);
      return sum;
    },
    totalPaidInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestPaid;
      }, 0);
      return sum;
    },
    totalPaidFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feesPaid;
      }, 0);
      return sum;
    },
    totalPaidPen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyPaid;
      }, 0);
      return sum;
    },
    totalPaidSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalPaid;
      }, 0);
      return sum;
    },

    //Amount Due
    //Amount paid
    totalDueAmt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.principalDue;
      }, 0);
      return sum;
    },
    totalDueInt(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.interestDue;
      }, 0);
      return sum;
    },
    totalDueFee(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.feesDue;
      }, 0);
      return sum;
    },
    totalDuePen(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.penaltyDue;
      }, 0);
      return sum;
    },
    totalDueSum(array) {
      if (!array) return 0;
      const sum = array.reduce((accumulator, object) => {
        return accumulator + object.totalDue;
      }, 0);
      return sum;
    },

    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      if (isNaN(value)) {
        return "_";
      }
      return "₦" + val;
    },
  },
};
</script>
<style lang="scss" scoped>
.table__amount {
  &__right {
    border-right: 2px solid rgba(224, 224, 224, 0.8);
    padding-right: 10px !important;

    & span {
      padding-right: 10px !important;
    }
  }

  &__left {
    border-left: 2px solid rgba(224, 224, 224, 0.8);
    padding-left: 10px !important;

    & span {
      padding-left: 10px !important;
    }
  }
}

._customtable {
  width: 100%;
}

.label-des {
  width: 80%;
}
</style>
