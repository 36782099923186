<template>
  <div
    v-if="showEditField"
    :class="{ show: showEditField === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Edit Custom Field
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                ref="close"
                @click="closeModal"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit Field</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Field Set</label>
                <!-- <div
                  class="error--text"
                  v-if="!uiProps.errors.customFieldSet.valid"
                >
                  {{ uiProps.errors.customFieldSet.message }}
                </div> -->
                <select
                  class="select select--lg"
                  required
                  v-model="form.customFieldSet"
                >
                  <option
                    value=""
                    disabled
                    selected
                    v-if="loadingCustomFieldSets"
                  >
                    Loading...
                  </option>
                  <option
                    v-else
                    v-for="item in customFieldSets"
                    :key="item.customFieldSetKey"
                    :value="item.customFieldSetKey"
                  >
                    {{ item.customFieldSetName }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">Field Name</label>
                <div
                  class="error--text"
                  v-if="!uiProps.errors.customField.valid"
                >
                  {{ uiProps.errors.customField.message }}
                </div>
                <select
                  class="select select--lg"
                  required
                  v-model="form.customField"
                >
                  <option value="null" disabled>Select Field Name</option>
                  <option
                    v-for="item in uiProps.customFields"
                    :key="item.customFieldKey"
                    v-bind:value="item.customFieldKey"
                  >
                    {{ item.customFieldName }}
                  </option>
                </select>
              </div>
              <div class="form__item" v-if="uiProps.customFieldValue">
                <div
                  class="error--text"
                  v-if="!uiProps.errors.customFieldValue.valid"
                >
                  {{ uiProps.errors.customFieldValue.message }}
                </div>
                <!--TEXT/NUMBER-->
                <div
                  class="form__item"
                  v-if="
                    getFieldType(uiProps.customFieldValue.dataType) ===
                      'text' ||
                    getFieldType(uiProps.customFieldValue.dataType) === 'number'
                  "
                >
                  <label class="form__label"
                    >{{ uiProps.customFieldValue.customFieldName }}
                  </label>
                  <div class="d-flex align-items-center">
                    <input
                      type="text"
                      class="input input--right form__input form__input--lg"
                      v-model="form.customFieldValue"
                    />
                  </div>
                </div>
                <!--DATE-->
                <div
                  class="form__item"
                  v-else-if="
                    getFieldType(uiProps.customFieldValue.dataType) === 'date'
                  "
                >
                  <label class="form__label">{{
                    uiProps.customFieldValue.customFieldName
                  }}</label>
                  <div class="d-flex align-items-center">
                    <input
                      type="date"
                      class="input input--right form__input form__input--lg"
                      v-model="form.customFieldValue"
                    />
                  </div>
                </div>
                <!--CHECKBOX-->
                <div
                  class="form__item"
                  v-else-if="
                    getFieldType(uiProps.customFieldValue.dataType) ===
                    'checkbox'
                  "
                >
                  <label class="form__label">{{
                    uiProps.customFieldValue.customFieldName
                  }}</label>
                  <div class="d-flex align-items-center">
                    <label class="form__label has-checkbox">
                      <input
                        type="checkbox"
                        class="input input--right form__input form__input--lg"
                        v-model="form.customFieldValue"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <!--SELECT-->
                <div
                  class="form__item"
                  v-else-if="
                    getFieldType(uiProps.customFieldValue.dataType) === 'select'
                  "
                >
                  <label class="form__label">{{
                    uiProps.customFieldValue.customFieldName
                  }}</label>
                  <div class="d-flex align-items-center">
                    <select
                      class="select select--lg"
                      v-model="form.customFieldValue"
                    >
                      <option value="null" disabled selected>
                        Select {{ uiProps.customFieldValue.customFieldName }}
                      </option>
                      <option
                        v-for="itemSelection in uiProps.customFieldValue
                          .customFieldSelections"
                        :key="itemSelection.customFieldSelectionKey"
                        v-bind:value="itemSelection.value"
                      >
                        {{ itemSelection.value }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Link Types -->
                <div
                  class="form__item"
                  v-else-if="
                    getFieldType(uiProps.customFieldValue.dataType) ===
                      'user link' ||
                    getFieldType(uiProps.customFieldValue.dataType) ===
                      'group link' ||
                    getFieldType(uiProps.customFieldValue.dataType) ===
                      'customer link'
                  "
                >
                  <label class="form__label">{{
                    uiProps.customFieldValue.customFieldName
                  }}</label>
                  <div class="d-flex align-items-center">
                    <!-- <select
                      class="select select--lg"
                      v-model="form.customFieldValue"
                    >
                      <option value="null" disabled selected>
                        Select {{ uiProps.customFieldValue.customFieldName }}
                      </option>
                      <option
                        v-for="itemSelection in uiProps.customFieldValue
                          .customFieldSelections"
                        :key="itemSelection.customFieldSelectionKey"
                        v-bind:value="itemSelection.customFieldSelectionKey"
                      >
                        {{ itemSelection.value }}
                      </option>
                    </select> -->
                    <multiselect
                      v-model="linkFieldValue"
                      id="ajax"
                      label="name"
                      track-by="name"
                      :placeholder="`Type to search ${
                        uiProps.customFieldValue.dataType.split(' ')[0]
                      }`"
                      open-direction="bottom"
                      :options="searchValue"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="false"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="5"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @search-change="
                        querySearch($event, uiProps.customFieldValue.dataType)
                      "
                      @input="assignFieldValue"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    @click="confirmSave"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    Save
                  </button>
                  <a
                    role="button"
                    data-dismiss="#editLoanAccountFieldModal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GeneralApiService from "@/core/services/general.service";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

function initialState() {
  return {
    form: { customFieldSet: "null", customField: "null", customFieldValue: "" },
    uiProps: {
      errors: {
        customFieldSet: { valid: true, message: "" },
        customField: { valid: true, message: "" },
        customFieldValue: { valid: true, message: "" },
      },
      customFields: [],
      customFieldValue: null,
      customFieldSet: null,
    },
    confirm: false,
    process: false,
    searchValue: [],
    linkFieldValue: null,
  };
}
export default {
  name: "editLoanAccountFieldModal",
  props: {
    account: Object,
    customFieldSets: Array,
    customFields: Array,
    customFieldKey: String,
    customFieldSetKey: String,
    customFieldValue: String,
    accountCustomFieldSets: Array,
    loadingCustomFieldSets: Boolean,
    loadingAccountCustomFieldSets: Boolean,
    linkValue: Object,
    searchArray: Array,
    showEditField: Boolean,
  },
  components: {
    Multiselect,
  },
  data: function () {
    return initialState();
  },
  watch: {
    linkValue: function (newVal) {
      this.linkFieldValue = newVal;
    },
    searchArray: function (newVal) {
      this.searchValue = newVal;
    },
    "form.customFieldSet": function (change) {
      const customFieldSet = this.customFieldSets.find(
        (item) => item.customFieldSetKey == change
      );
      this.uiProps.customFields = customFieldSet.customFields;
      this.uiProps.customFieldSet = customFieldSet;
    },
    "form.customField": function (change) {
      const customFieldValue = this.uiProps.customFields.find(
        (item) => item.customFieldKey == change
      );
      this.uiProps.customFieldValue = customFieldValue;
      // console.log(customFieldValue)
    },
    loadingAccountCustomFieldSets: function (change) {
      if (change && this.loadingCustomFieldSets) {
        // console.log(this.customFieldSets, this.accountCustomFieldSets)
      }
    },
    loadingCustomFieldSets: function (change) {
      if (change && this.loadingAccountCustomFieldSets) {
        // console.log(this.customFieldSets, this.accountCustomFieldSets)
      }
    },
    customFieldSetKey(key) {
      this.form.customFieldSet = key;
      const customFieldSet = this.customFieldSets.find(
        (item) => item.customFieldSetKey == key
      );
      this.uiProps.customFields = customFieldSet.customFields;
      this.uiProps.customFieldSet = customFieldSet;
    },
    customFieldKey(key) {
      // console.log(key)
      this.form.customField = key;
    },
    customFieldValue(key) {
      this.form.customFieldValue = key;
    },
  },
  methods: {
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    assignFieldValue(value) {
      this.form.customFieldValue = value.linkKey;
    },
    async querySearch(query, type) {
      let searchQuery = query;
      this.searchValue = [];
      let searchArea =
        type.toLowerCase() == "customer link"
          ? "customer"
          : type.toLowerCase() == "group link"
          ? "group"
          : "user";

      try {
        const response = await ApiService.post(`GlobalSearch`, {
          searchQuery,
          searchCategory: searchArea,
        });
        this.searchValue = response.data.data;
      } catch (e) {
        this.searchValue = [];
      }
    },
    save: function () {
      this.process = true;
      // const additionalFields = this.customFields
      //   .filter((item) => item.fieldKey !== this.form.customField)
      //   .map((item) => {
      //     return {
      //       customFieldKey: item.fieldKey,
      //       value: item.value,
      //     };
      //   });
      const p = {
        entityKey: this.account.id,
        // entityTypeKey: "LoanAccount",
        entityTypeKey: this.account.id,
        customFieldValues: [
          {
            customFieldKey: this.form.customField,
            value: this.form.customFieldValue,
          },
          // ...additionalFields,
        ],
      };
      GeneralApiService.post("CustomFields/FieldValues", p)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          // this.form.customFieldValue = "";
          if (res.status) {
            this.$emit("reload");
            this.$emit("alertSuccess", res.message);
            this.$refs.close.click();
          } else {
            this.$emit(
              "alertError",
              res.message.length
                ? res.message
                : "Unable to save Custom Field currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to save Comment currently"
            );
          } catch (e) {
            // console.log(e.message);
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.customFieldSet === "null") {
        this.uiProps.errors.customFieldSet.valid = false;
        this.uiProps.errors.customFieldSet.message =
          "A custom fieldset is required";
        return false;
      } else if (this.form.customField === "null") {
        this.uiProps.errors.customField.valid = false;
        this.uiProps.errors.customField.message = "A custom field is required";
        return false;
      } else if (this.form.customFieldValue.length < 2) {
        this.uiProps.errors.customFieldValue.valid = false;
        this.uiProps.errors.customFieldValue.message =
          "A custom field value is required";
        return false;
      } else {
        return true;
      }
    },
    closeModal() {
      this.$emit("close-edit-field");
      // console.log()
      // this.showEditField =! this.showEditField;
    },
    getFieldType(type) {
      if (type.indexOf("Number") !== -1) {
        return "number";
      } else if (type.indexOf("FreeText") !== -1) {
        return "text";
      } else if (type.indexOf("Selection") !== -1) {
        return "select";
      } else if (type.indexOf("Checkbox") !== -1) {
        return "checkbox";
      } else if (type.indexOf("Date") !== -1) {
        return "date";
      } else if (type.indexOf("User link") !== -1) {
        return "user link";
      } else if (type.indexOf("Group link") !== -1) {
        return "group link";
      } else if (type.indexOf("Customer link") !== -1) {
        return "customer link";
      }
      return "text";
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
  },
  computed: {
    ...mapState({
      account_details: (state) =>
        state.loan.account_details ? state.loan.account_details.data : null,
    }),
    editField() {
      return this.showEditField;
    },
  },
};
</script>
