<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="editScheduleModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Edit Schedule
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editScheduleModal"
              ref="close"
              class="modal__close modal__close--black"
              @click="closeEditScheduleModal"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">
            Editing Repayment Schedule
          </h5>
          <div class="error--text" v-if="!uiProps.errors.scheduleEdits.valid">
            {{ uiProps.errors.scheduleEdits.message }}
          </div>
        </div>
        <div class="modal__body">
          <form v-if="!loading && repaymentSchedules">
            <div class="mt-5 pb5">
              <div class="row py-3 border-bottom align-items-center">
                <div class="col-3">
                  <span
                    class="
                      text--grey text--tiny
                      d-block
                      text--600 text--capital
                    "
                  >
                    Due Date
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--grey text--tiny
                      d-block
                      text--600 text--capital
                    "
                  >
                    Principal
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--grey text--tiny
                      d-block
                      text--600 text--capital
                    "
                  >
                    Interest
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--grey text--tiny
                      d-block
                      text--600 text--capital
                    "
                  >
                    Fee
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--grey text--tiny
                      d-block
                      text--600 text--capital
                    "
                  >
                    Penalty
                  </span>
                </div>
              </div>
              <div
                class="row py-3 border-bottom align-items-center"
                v-for="(item, i) in repaymentSchedules.generalRepayment"
                :key="i"
              >
                <div class="col-3">
                  <input
                    type="date"
                    class="input form__input form__input--lg"
                    :value="new Date(item.dueDate).toISOString().slice(0, 10)"
                    @input="updateAccount($event, i, item, 'date')"
                    :disabled="!account.editScheduleSettings.adjustPaymentDates"
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    :value="inputInterestFormat(item.principalDue)"
                    @blur="updateAccount($event, i, item, 'principal')"
                    :disabled="
                      !account.editScheduleSettings.adjustPrincipalPayment
                    "
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    :value="inputInterestFormat(item.interestDue)"
                    @blur="updateAccount($event, i, item, 'interest')"
                    :disabled="
                      !account.editScheduleSettings.adjustInterestPayment
                    "
                  />
                </div>
                <div class="col">
                  <input
                    type="number"
                    class="input form__input form__input--lg"
                    :value="inputCurrencyFormat(item.feesDue)"
                    @blur="updateAccount($event, i, item, 'fees')"
                    min="0"
                    :disabled="!account.editScheduleSettings.adjustFeePayment"
                  />
                </div>
                <div class="col">
                  <input
                    type="number"
                    class="input form__input form__input--lg"
                    :value="inputCurrencyFormat(item.penaltyDue) ? inputCurrencyFormat(item.penaltyDue) : inputCurrencyFormat(0)"
                    @blur="updateAccount($event, i, item, 'penalty')"
                    min="0"
                    :disabled="
                      !account.editScheduleSettings.adjustPenaltyPayment
                    "
                  />
                </div>
              </div>

              <div class="row py-4 align-items-center">
                <div class="col-3">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500 text--capital
                    "
                  >
                    Changes
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500 text--capital
                    "
                  >
                    {{ getPrincipalChanges | currency }}
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500 text--capital
                    "
                  >
                    {{ uiProps.interestChanges | currency }}
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500 text--capital
                    "
                  >
                    {{ uiProps.feesChanges | currency }}
                  </span>
                </div>
                <div class="col">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500 text--capital
                    "
                  >
                    {{ uiProps.penaltyChanges | currency }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form__item mt5">
              <div class="form__action">
                <button
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  class="
                    button button--primary
                    ml-3
                    form__button form__button--md
                    text--white
                  "
                  @click="resetChanges"
                >
                  reset
                </button>
                <a
                  @click="closeEditScheduleModal"
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
          <div v-else class="text-center">Loading...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
import currencyMixin from "@/mixins/currencyMixin"; 

function initialState() {
  return {
    form: { scheduleEdits: [], schedules: [] },
    uiProps: {
      errors: {
        scheduleEdits: { valid: true, message: "" },
      },
      principalChanges: 0,
      interestChanges: 0,
      feesChanges: 0,
      penaltyChanges: 0,
      repaymentSchedulesDefault: null,
    },
    principalChanges: '',
    interestChanges: '',
    feesChanges: '',
    penaltyChanges: '',
    repayments: {},
    confirm: false,
    CurrentPrincipalAmount: 0,
    process: false,
    repaymentSchedules: null,
  };
}
export default {
  name: "EditScheduleModal",
  mixins: [currencyMixin],
  props: {
    account: Object,
    repaymentSchedulesProp: Object,
    repaymentSchedulesPropOption: Object,
    loading: Boolean,
    show: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    numberIsValid(amount) {
      if(!this.isValidNumberInput(amount)) return true;
      return false;
    },
    formatAndValidateAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
          this.theInput.fee = amtPaid;
          this.amount = amountInputted;
          return amountInputted;
      }
      this.theInput.fee = this.theInput.fee;
      this.amount = '';
    },
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    closeEditScheduleModal() {
      this.resetChanges();
      this.$emit('close-edit-schedule');
    },
    save: function () {
      this.process = true;
      const sche = {
        loanAccountKey: this.account.id,
        scheduleEdits: this.form.scheduleEdits.map((item) => ({
          principal: item.principal,
          scheduleKey: item.scheduleKey,
          fees: item.fees,
          penalty: item.penalty,
          interest: item.interest,
          due: item.due,
        })),
      };
      LoanApiService.post("LoanAccount/edit-loan-account-schedule", sche)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("get-transactions");
            this.$emit("get-schedules");
            this.$emit("get-activities");
            this.$emit("alertSuccess", res.message);
            this.$refs.close.click();
          } else {
            this.$emit(
              "alertError",
              res.message.length
                ? res.message
                : "Unable to edit Schedule currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to edit Schedule currently"
            );
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.scheduleEdits.length < 1) {
        this.uiProps.errors.scheduleEdits.valid = false;
        this.uiProps.errors.scheduleEdits.message =
          "You haven't made any edits yet";
        return false;
      } else {
        return true;
      }
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    resetPrincipal() {
      this.uiProps.principalChanges = 0;
    },
    resetInterestChanges() {
      this.uiProps.interestChanges = 0;
    },
    resetFeesChanges() {
      this.uiProps.feesChanges = 0;
    },
    resetPenaltyChanges() {
      this.uiProps.penaltyChanges = 0;
    },
    resetChanges() {
      const p = JSON.parse(JSON.stringify(this.repaymentSchedulesProp));
      this.resetPrincipal();
      this.resetInterestChanges();
      this.resetFeesChanges();
      this.resetPenaltyChanges();
      this.repaymentSchedules = null;
      this.repaymentSchedules = p;
      this.resetErrors();
      // this.form.scheduleEdits = [];
      this.reset = true;
    },
    updateAccount(e, i, item, p) {
      if (p == "date") {
        const copyOfPrincipalAmount = [
          ...this.repaymentSchedules.generalRepayment,
        ];

        copyOfPrincipalAmount[i].principal =
          copyOfPrincipalAmount[i].principalDue;
        copyOfPrincipalAmount[i][p] = e.target.value;
        copyOfPrincipalAmount[i].fees = copyOfPrincipalAmount[i].feesDue;
        copyOfPrincipalAmount[i].penalty = copyOfPrincipalAmount[i].penaltyDue;
        copyOfPrincipalAmount[i].interest =
          copyOfPrincipalAmount[i].interestDue;
        copyOfPrincipalAmount[i].scheduleKey =
          copyOfPrincipalAmount[i].repaymentKey;

        this.form.scheduleEdits = copyOfPrincipalAmount;
      }
      if (p == "principal") {
        const copyOfPrincipalAmount = [
          ...this.repaymentSchedules.generalRepayment,
        ];
        const copyOfPrincipal = [...this.repaymentSchedules.generalRepayment];

        copyOfPrincipalAmount[i].due = copyOfPrincipalAmount[i].dueDate;
        copyOfPrincipalAmount[i][p] = e.target.value;
        copyOfPrincipalAmount[i].fees = copyOfPrincipalAmount[i].feesDue;
        copyOfPrincipalAmount[i].penalty = copyOfPrincipalAmount[i].penaltyDue;
        copyOfPrincipalAmount[i].interest =
          copyOfPrincipalAmount[i].interestDue;
        copyOfPrincipalAmount[i].scheduleKey =
          copyOfPrincipalAmount[i].repaymentKey;

        copyOfPrincipal[i].due = copyOfPrincipal[i].dueDate;

        //here
        const formatAndValidateAmount = (amount) => {
          const amountValue = amount;
          const amtPaid = this.convertFigureToNumber(amountValue);
          const amountInputted = this.inputCurrencyFormat(amtPaid);

          if (this.validateDepositAmount(amountValue)) {
              copyOfPrincipal[i].principalDue = amtPaid;
              return amountInputted;
          }
          copyOfPrincipal[i].principalDue = '';
        };

        formatAndValidateAmount(e.target.value);
        copyOfPrincipal[i].fees = copyOfPrincipal[i].feesDue;
        copyOfPrincipal[i].penalty = copyOfPrincipal[i].penaltyDue;
        copyOfPrincipal[i].interest = copyOfPrincipal[i].interestDue;
        copyOfPrincipal[i].scheduleKey = copyOfPrincipal[i].repaymentKey;

        this.form.schedules = copyOfPrincipal;

        this.form.scheduleEdits = copyOfPrincipalAmount;
      } else if (p == "interest") {
        const copyOfPrincipalAmount = [
          ...this.repaymentSchedules.generalRepayment,
        ];
        const copyOfInterest = [...this.repaymentSchedules.generalRepayment];

        copyOfPrincipalAmount[i].due = copyOfPrincipalAmount[i].dueDate;
        copyOfPrincipalAmount[i][p] = e.target.value;
        copyOfPrincipalAmount[i].fees = copyOfPrincipalAmount[i].feesDue;
        copyOfPrincipalAmount[i].penalty = copyOfPrincipalAmount[i].penaltyDue;
        copyOfPrincipalAmount[i].scheduleKey =
          copyOfPrincipalAmount[i].repaymentKey;
        copyOfPrincipalAmount[i].principal =
          copyOfPrincipalAmount[i].principalDue;

        copyOfInterest[i].due = copyOfInterest[i].dueDate;

        //here
         const formatAndValidateInterest = (amount) => {
          const amountValue = amount;
          const amtPaid = this.convertFigureToNumber(amountValue);
          const amountInputted = this.inputInterestFormat(amtPaid);

          if (this.validateDepositAmount(amountValue)) {
              copyOfInterest[i].interestDue = amtPaid;
              return amountInputted;
          }
          copyOfInterest[i].interestDue = '';
        };
        formatAndValidateInterest(e.target.value);
        copyOfInterest[i].fees = copyOfInterest[i].feesDue;
        copyOfInterest[i].penalty = copyOfInterest[i].penaltyDue;
        copyOfInterest[i].scheduleKey = copyOfInterest[i].repaymentKey;
        copyOfInterest[i].principal = copyOfInterest[i].principalDue;

        this.form.schedules = copyOfInterest;

        this.form.scheduleEdits = copyOfInterest;
      } else if (p == "fees") {
        const copyOfPrincipalAmount = [
          ...this.repaymentSchedules.generalRepayment,
        ];
        const copyOfFees = [...this.repaymentSchedules.generalRepayment];

        copyOfPrincipalAmount[i][p] = e.target.value;
        copyOfPrincipalAmount[i].due = copyOfPrincipalAmount[i].dueDate;
        copyOfPrincipalAmount[i].interest =
          copyOfPrincipalAmount[i].interestDue;
        copyOfPrincipalAmount[i].penalty = copyOfPrincipalAmount[i].penaltyDue;
        copyOfPrincipalAmount[i].scheduleKey =
          copyOfPrincipalAmount[i].repaymentKey;
        copyOfPrincipalAmount[i].principal =
          copyOfPrincipalAmount[i].principalDue;

        //here
         const formatAndValidateFee = (amount) => {
          const amountValue = amount;
          const amtPaid = this.convertFigureToNumber(amountValue);
          const amountInputted = this.inputCurrencyFormat(amtPaid);

          if (this.validateDepositAmount(amountValue)) {
              copyOfFees[i].feesDue = amtPaid;
              return amountInputted;
          }
          copyOfFees[i].feesDue = '';
        };

        formatAndValidateFee(e.target.value);
        copyOfFees[i].due = copyOfFees[i].dueDate;
        copyOfFees[i].interest = copyOfFees[i].interestDue;
        copyOfFees[i].penalty = copyOfFees[i].penaltyDue;
        copyOfFees[i].scheduleKey = copyOfFees[i].repaymentKey;
        copyOfFees[i].principal = copyOfFees[i].principalDue;

        this.form.schedules = copyOfFees;

        this.form.scheduleEdits = copyOfPrincipalAmount;
      } else if (p == "penalty") {
        const copyOfPrincipalAmount = [
          ...this.repaymentSchedules.generalRepayment,
        ];
        const copyOfPenalty = [...this.repaymentSchedules.generalRepayment];

        copyOfPrincipalAmount[i].due = copyOfPrincipalAmount[i].dueDate;
        copyOfPrincipalAmount[i][p] = e.target.value;
        copyOfPrincipalAmount[i].fees = copyOfPrincipalAmount[i].feesDue;
        copyOfPrincipalAmount[i].interest =
          copyOfPrincipalAmount[i].interestDue;
        copyOfPrincipalAmount[i].scheduleKey =
          copyOfPrincipalAmount[i].repaymentKey;
        copyOfPrincipalAmount[i].principal =
          copyOfPrincipalAmount[i].principalDue;

        copyOfPenalty[i].due = copyOfPenalty[i].dueDate;

        const formatAndValidatePenalty = (amount) => {
          const amountValue = amount;
          const amtPaid = this.convertFigureToNumber(amountValue);
          const amountInputted = this.inputCurrencyFormat(amtPaid);

          if (this.validateDepositAmount(amountValue)) {
              copyOfPenalty[i].penaltyDue = amtPaid;
              return amountInputted;
          }
          copyOfPenalty[i].penaltyDue = '';
        };

        formatAndValidatePenalty(e.target.value);
        copyOfPenalty[i].fees = copyOfPenalty[i].feesDue;
        copyOfPenalty[i].interest = copyOfPenalty[i].interestDue;
        copyOfPenalty[i].scheduleKey = copyOfPenalty[i].repaymentKey;
        copyOfPenalty[i].principal = copyOfPenalty[i].principalDue;

        this.form.schedules = copyOfPenalty;

        this.form.scheduleEdits = copyOfPrincipalAmount;
      } else {
        this.form.scheduleEdits = [];
      }
    },
    /*updatePrincipal(e, i) {
      const copySchedules = JSON.parse(
        JSON.stringify(this.repaymentSchedules.generalRepayment)
      );
      this.repaymentSchedules.generalRepayment = copySchedules.map(
        (item, index) => {
          if (index === i) {
            item.principalPaid = e.target.value;
          }
          return item;
        }
      );
      const pDefault = this.uiProps.repaymentSchedulesDefault.find(
        (item, index) => index === i
      );
      const getAllPrincipalAmount =
        this.repaymentSchedulesPropOption.generalRepayment.reduce(
          (accumulator, current) => accumulator + current.principalPaid,
          0
        );
      const p = this.repaymentSchedules.generalRepayment.find(
        (item, index) => index === i
      );
      if (pDefault.principalPaid > parseFloat(e.target.value)) {
        this.updateSchedules(p);
        const getCurrentPrincipalAmount = this.form.scheduleEdits.reduce(
          (accumulator, current) => accumulator + parseFloat(current.principal),
          0
        );
        // console.log(getCurrentPrincipalAmount);
        const remainderValue = parseFloat(
          getAllPrincipalAmount - getCurrentPrincipalAmount
        );
        this.uiProps.principalChanges = 
          // pDefault.principalPaid - parseFloat(p.principalPaid)
          // Math.floor(Math.random() * (getAllPrincipalAmount - parseFloat(getCurrentPrincipalAmount) + 1)) + parseFloat(getCurrentPrincipalAmount)
          // getAllPrincipalAmount - parseFloat(getCurrentPrincipalAmount)
          getAllPrincipalAmount - remainderValue
          // difference(parseFloat(getAllPrincipalAmount), parseFloat(getCurrentPrincipalAmount))
        ;
        //update edits
      } else if (pDefault.principalPaid < parseFloat(e.target.value)) {
        this.updateSchedules(p);
        const getCurrentPrincipalAmount = this.form.scheduleEdits.reduce(
          (accumulator, current) => accumulator + parseFloat(current.principal),
          0
        );
        const remainderValue = parseFloat(
          getAllPrincipalAmount - getCurrentPrincipalAmount
        );
        this.uiProps.principalChanges = getAllPrincipalAmount - remainderValue;
        // (difference(parseFloat(getAllPrincipalAmount), getCurrentPrincipalAmount));
        //update edits
      } else {
        this.uiProps.principalChanges = 0;
        //update edits
        this.updateSchedules(p);
      }
    },*/
    updateFees(e, i) {
      const copySchedules = JSON.parse(
        JSON.stringify(this.repaymentSchedules.generalRepayment)
      );
      this.repaymentSchedules.generalRepayment = copySchedules.map(
        (item, index) => {
          if (index === i) {
            item.feesDue = e.target.value;
          }
          return item;
        }
      );
      const pDefault = this.uiProps.repaymentSchedulesDefault.find(
        (item, index) => index === i
      );
      const p = this.repaymentSchedules.generalRepayment.find(
        (item, index) => index === i
      );
      if (pDefault.feesDue > parseFloat(e.target.value)) {
        this.uiProps.interestChanges = -(
          pDefault.feesDue - parseFloat(p.feesDue)
        );
        //update edits
        this.updateSchedules(p);
      } else if (pDefault.feesDue < parseFloat(e.target.value)) {
        this.uiProps.feesChanges = parseFloat(p.feesDue) - pDefault.feesDue;
        //update edits
        this.updateSchedules(p);
      } else if (p.feesDue === parseFloat(e.target.value)) {
        this.uiProps.feesChanges = 0;
        //update edits
        this.updateSchedules(p, true);
      }
    },
    updatePenalty(e, i) {
      const copySchedules = JSON.parse(
        JSON.stringify(this.repaymentSchedules.generalRepayment)
      );
      this.repaymentSchedules.generalRepayment = copySchedules.map(
        (item, index) => {
          if (index === i) {
            item.penaltyDue = e.target.value;
          }
          return item;
        }
      );
      const pDefault = this.uiProps.repaymentSchedulesDefault.find(
        (item, index) => index === i
      );
      const p = this.repaymentSchedules.generalRepayment.find(
        (item, index) => index === i
      );
      if (pDefault.penaltyDue > parseFloat(e.target.value)) {
        this.uiProps.feesChanges = -(
          pDefault.penaltyDue - parseFloat(p.penaltyDue)
        );
        //update edits
        this.updateSchedules(p);
      } else if (pDefault.penaltyDue < parseFloat(e.target.value)) {
        this.uiProps.penaltyChanges =
          parseFloat(p.penaltyDue) - pDefault.penaltyDue;
        //update edits
        this.updateSchedules(p);
      } else if (p.penaltyDue === parseFloat(e.target.value)) {
        this.uiProps.penaltyChanges = 0;
        //update edits
        this.updateSchedules(p, true);
      }
    },
    updateInterest(e, i) {
      const copySchedules = JSON.parse(
        JSON.stringify(this.repaymentSchedules.generalRepayment)
      );
      this.repaymentSchedules.generalRepayment = copySchedules.map(
        (item, index) => {
          if (index === i) {
            item.interestDue = e.target.value;
          }
          return item;
        }
      );
      const pDefault = this.uiProps.repaymentSchedulesDefault.find(
        (item, index) => index === i
      );
      const p = this.repaymentSchedules.generalRepayment.find(
        (item, index) => index === i
      );
      if (pDefault.interestDue > parseFloat(e.target.value)) {
        this.uiProps.interestChanges = -(
          pDefault.interestDue - parseFloat(p.interestDue)
        );
        //update edits
        this.updateSchedules(p);
      } else if (pDefault.interestDue < parseFloat(e.target.value)) {
        this.uiProps.interestChanges =
          parseFloat(p.interestDue) - pDefault.interestDue;
        //update edits
        this.updateSchedules(p);
      } else if (p.interestDue === parseFloat(e.target.value)) {
        this.uiProps.interestChanges = 0;
        //update edits
        this.updateSchedules(p, true);
      }
    },
    updateSchedules(p, d = false) {
      const copyScheduleEdits = this.form.scheduleEdits;
      const findEditIndex = copyScheduleEdits.findIndex(
        (item) => item.scheduleKey == p.repaymentKey
      );
      if (d) {
        copyScheduleEdits.filter((item, i) => i !== findEditIndex);
        this.form.scheduleEdits = copyScheduleEdits;
      } else if (findEditIndex !== -1) {
        copyScheduleEdits[findEditIndex].principal = p.principalDue;
        copyScheduleEdits[findEditIndex].interest = p.interestDue;
      } else {
        const edit = {
          scheduleKey: p.repaymentKey,
          principal: p.principalDue,
          interest: p.interestDue,
          fee: p.feesDue,
        };
        this.form.scheduleEdits.push(edit);
      }
    },
    close() {
      this.$refs.close.click();
    },
  },
  computed: {
    count() {
      return this.form.scheduleEdits.length;
    },
    getPrincipal() {
      return (this.form.scheduleEdits.map((form) => form.principal) || this.form.scheduleEdits.mpa((form) => form.principalDue));
    },
    getInterest() {
      return this.form.scheduleEdits.map((form) => form.interest);
    },
    getFees() {
      return this.form.scheduleEdits.map((form) => form.fees);
    },
    getPenalty() {
      return this.form.scheduleEdits.map((form) => form.penaltyDue);
    },
    getPrincipalChanges() {
      return this.uiProps.principalChanges;
    }
  },
  created() {
    this.repaymentSchedules = this.$watch('repaymentSchedulesProp', (change) => {
      if (change.hasOwnProperty("generalRepayment")) {
        this.uiProps.repaymentSchedulesDefault = change.generalRepayment;
        this.repaymentSchedules = JSON.parse(JSON.stringify(change));
      }
    })
  },
  // mounted() {
  //   this.repaymentSchedules = this.$watch('repaymentSchedulesProp', (newVal) => {
  //     this.repayments = newVal;
  //     this.addRepayments();
  //   });
  //   this.repaymentSchedules = this.repayments;
  // },
  watch: {
    // repaymentSchedulesProp(change) {
    //   if (change.hasOwnProperty("generalRepayment")) {
    //     this.uiProps.repaymentSchedulesDefault = change.generalRepayment;
    //     this.repaymentSchedules = JSON.parse(JSON.stringify(change));
    //   }
    // },
    getPrincipal() {
      let currentResult = this.form.schedules.map((i) =>
        Number(i.principalDue)
      );
      let totalResult = this.repaymentSchedulesProp.generalRepayment.map((i) =>
        Number(i.principalDue)
      );
      let CurrentPrincipalBalance = currentResult.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let totalRepayment = totalResult.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let remainderBalance = totalRepayment - CurrentPrincipalBalance;
      // console.log('current resutlt', currentResult);
      // console.log('total result', totalResult);
      // console.log('current principal balance', CurrentPrincipalBalance);
      // console.log('total repayment', totalRepayment);
      // console.log('remainder balance', remainderBalance);
      if (totalRepayment < CurrentPrincipalBalance) {
        this.uiProps.principalChanges = 
          totalRepayment - CurrentPrincipalBalance;
      } else {
        this.uiProps.principalChanges = remainderBalance;
      }
    },
    getInterest() {
      let currentInterest = this.form.schedules.map((i) =>
        Number(i.interestDue)
      );
      let totalInterest = this.repaymentSchedulesProp.generalRepayment.map(
        (i) => Number(i.interestDue)
      );
      let CurrentInterestBalance = currentInterest.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let totalInterestRepayment = totalInterest.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let remainderInterestBalance =
        totalInterestRepayment - CurrentInterestBalance;
      if (totalInterestRepayment < CurrentInterestBalance) {
        this.uiProps.interestChanges = 
          totalInterestRepayment - CurrentInterestBalance;
      } else {
        this.uiProps.interestChanges = remainderInterestBalance;
      }
    },
    getFees() {
      let currentFee = this.form.schedules.map((i) => Number(i.feesDue));
      let totalFee = this.repaymentSchedulesProp.generalRepayment.map((i) =>
        Number(i.feesDue)
      );
      let CurrentFeeBalance = currentFee.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let totalFeeRepayment = totalFee.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let remainderFeeBalance = totalFeeRepayment - CurrentFeeBalance;
      if (totalFeeRepayment < CurrentFeeBalance) {
        this.uiProps.feesChanges = 
          totalFeeRepayment - CurrentFeeBalance;
      } else {
        this.uiProps.feesChanges = remainderFeeBalance;
      }
    },
    getPenalty() {
      let currentPenalty = this.form.schedules.map((i) => Number(i.penaltyDue));
      let totalPenalty = this.repaymentSchedulesProp.generalRepayment.map((i) =>
        Number(i.penaltyDue)
      );
      let CurrentPenaltyBalance = currentPenalty.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let totalPenaltyRepayment = totalPenalty.reduce(
        (accumulator, current) => accumulator + current,
        0
      );
      let remainderPenaltyBalance =
        totalPenaltyRepayment - CurrentPenaltyBalance;
      if (totalPenaltyRepayment < CurrentPenaltyBalance) {
        this.uiProps.penaltyChanges = 
          totalPenaltyRepayment - CurrentPenaltyBalance;
      } else {
        this.uiProps.penaltyChanges = remainderPenaltyBalance;
      }
    },
    form(change) {
      if (change.hasOwnProperty("scheduleEdits")) {
        const cp = this.form.scheduleEdits.reduce(
          (accumulator, current) => accumulator + parseFloat(current.principal),
          0
        );
        this.CurrentPrincipalAmount = cp;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal__right .modal__dialog {
  width: 85rem;
}
.modal__content {
  padding: 4.5rem 2.5rem;
}
</style>
