<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="reFinanceModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Refinance of Loan Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="confirmRefinance()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM REFINANCE-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: refinance ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="closeRefinanceModal()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Refinance of Loan Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                {{ message }}
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="closeRefinanceModal()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Proceed" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a class="modal__close modal__close--black" @click="closeModal">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Refinancing Loan Account ({{ account ? account.loanName : "" }})
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label text--regular text--black"
                  >Account Recipient</label
                >
                <p class="text--600 text--capital text--large text--primary">
                  {{ accountRecipient }}
                </p>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Product</label>
                    <select
                      class="select select--lg"
                      v-model="uiProps.productTypeKey"
                      @change="changeSelectedProduct"
                      :disabled="uiProps.productTypeKey"
                    >
                      <option value="null" disabled selected v-if="!loading">
                        Select Product
                      </option>
                      <option value="null" disabled selected v-else>
                        Loading...
                      </option>
                      <template v-if="!loading">
                        <option
                          v-for="item in products"
                          :key="item.loanProductKey"
                          v-bind:value="item.loanProductKey"
                        >
                          {{ item.productName }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Display Name</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Display Name"
                      v-model="uiProps.loanName"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Product Type</label>
                    <p
                      class="text--black text--600 text--capital text--regular"
                    >
                      {{ uiProps.loanProductType }}
                    </p>
                    <p class="text--black text--500 text--small">
                      {{ loanProductDescription }}
                    </p>
                  </div>
                </div>
              </div>
              <ul id="accordion">
                <!--OUTSTANDING BALANCES-->
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#outstandingBalances"
                    aria-expanded="false"
                    aria-controls="outstandingBalances"
                    ref="outstandingBalances"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      outstanding balances
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="outstandingBalances"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Interest Balance</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              :class="{
                                'input-error':
                                  !uiProps.errors.interestBalance.valid ||
                                  numberIsValid(interestBalanceAmt),
                              }"
                              :value="interestBalanceAmt"
                              @input="numberIsValid(interestBalanceAmt)"
                              @blur="
                                formatAndValidateInterestBalanceAmt($event)
                              "
                              :disabled="true"
                            />
                            <div
                              class="error--text"
                              v-if="!uiProps.errors.interestBalance.valid"
                            >
                              {{ uiProps.errors.interestBalance.message }}
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Fee Balance</label>
                            <input
                              type="text"
                              class="input input--right form__input form__input--lg"
                              :class="{
                                'input-error':
                                  !uiProps.errors.feeBalance.valid ||
                                  numberIsValid(feeBalanceAmount),
                              }"
                              :value="feeBalanceAmount"
                              @input="numberIsValid(feeBalanceAmount)"
                              @blur="formatAndValidateFeeBalanceAmount($event)"
                              :disabled="true"
                            />
                            <div
                              class="error--text"
                              v-if="!uiProps.errors.feeBalance.valid"
                            >
                              {{ uiProps.errors.feeBalance.message }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Penalty Balance</label>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :class="{
                              'input-error':
                                !uiProps.errors.penaltyBalance.valid ||
                                numberIsValid(penaltyBalanceAmt),
                            }"
                            :value="penaltyBalanceAmt"
                            @input="numberIsValid(penaltyBalanceAmt)"
                            @blur="formatAndValidatefPenaltyBalanceAmt($event)"
                            :disabled="true"
                          />
                          <div
                            class="error--text"
                            v-if="!uiProps.errors.penaltyBalance.valid"
                          >
                            {{ uiProps.errors.penaltyBalance.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--ACCOUNT TERMS-->
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#accountSettings"
                    aria-expanded="false"
                    aria-controls="accountSettings"
                    ref="accountSettings"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      account terms
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="accountSettings"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Top Up Amount</label>
                            <input
                              type="text"
                              :class="[
                                'input input--right',
                                'form__input form__input--lg',
                                { 'input-error': numberIsValid(topAmount) },
                              ]"
                              :value="topAmount"
                              @input="numberIsValid(topAmount)"
                              @blur="formatAndValidateTopAmount($event)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >Current Outstanding Principal</label
                            >
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span
                                  class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                                >
                                  %
                                </span>
                              </div>
                              <input
                                v-model.number="
                                  form.principalPaymentSettings.amount
                                "
                                type="number"
                                class="input input--right form__input form__input--lg"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Interest Rate</label>
                            <input
                              v-model.number="
                                form.interestSettings.interestRate
                              "
                              type="number"
                              class="input input--right form__input form__input--lg"
                            />
                            <small
                              v-if="
                                loanMinInterestRate > 0 ||
                                loanMaxInterestRate > 0
                              "
                              >Min: {{ loanMinInterestRate }}% Max:
                              {{ loanMaxInterestRate }}%</small
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 d-flex justify-content-between">
                          <div class="form__item p-2">
                            <div style="display: inline-block">
                              <label class="form__label">Repaid Every </label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                v-model.number="
                                  form.scheduleSettings.repaymentPeriodCount
                                "
                              />
                            </div>
                            <div style="display: inline-block">
                              <label class="form__label"></label>
                              <div
                                style="
                                  padding-top: 25px;
                                  font-weight: bold;
                                  font-size: 1.3em;
                                  padding-left: 5px;
                                "
                              >
                                {{ loanInterestType }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 d-flex justify-content-around">
                          <div class="form__item flex-grow-1 p-2">
                            <label class="form__label">Over</label>
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg flex-grow-0"
                                :min="0"
                                v-model.number="
                                  form.scheduleSettings.repaymentInstallments
                                "
                                @input="validateInstalment"
                              />
                              <span
                                class="flex-grow-1 p-2"
                                style="font-weight: bold; font-size: 1.3em"
                                >Installments</span
                              >
                            </div>
                            <small
                              v-if="
                                minFirstRepaymentDueDateOffset > 0 &&
                                maxFirstRepaymentDueDateOffset > 0
                              "
                              >Min: {{ minFirstRepaymentDueDateOffset }} Max:
                              {{ maxFirstRepaymentDueDateOffset }}</small
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >Arrears Tolerance Period</label
                            >
                            <input
                              type="number"
                              class="input input--right form__input form__input--lg"
                              v-model.number="
                                form.accountArrearsSettings.tolerancePeriod
                              "
                              @input="validateTolerancePeriod"
                            />
                            <small
                              >Min: {{ minTolerancePeriod }} days Max:
                              {{ maxTolerancePeriod }} days</small
                            >
                            <!-- <div
                              style="
                                display: inline-block;
                                padding-top: 25px;
                                font-weight: bold;
                                font-size: 1.3em;
                                padding-left: 5px;
                              "
                            >
                              Days
                            </div> -->
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >Arrears Tolerance Amount</label
                            >
                            <input
                              type="number"
                              class="input input--right form__input form__input--lg"
                              v-model.number="
                                form.accountArrearsSettings
                                  .tolerancePercentageOfOutstandingPrincipal
                              "
                              @input="validateToleranceAmount"
                            />
                            <small
                              v-if="
                                minToleranceAmount > 0 || maxToleranceAmount > 0
                              "
                            >
                              Min:
                              {{ minToleranceAmount }}
                              Max:
                              {{ maxToleranceAmount }}
                              % of outstanding principal</small
                            >
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form__item">
                            <label class="form__label"
                              >Interest Calculation Method</label
                            >
                            <p
                              class="text--black text--600 text--capital text--regular"
                            >
                              {{ loanInterestCalculationMethod }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--DISBURSEMENT DETAILS-->
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#disbursement"
                    aria-expanded="false"
                    aria-controls="disbursement"
                    ref="disbursement"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Disbursement Details
                      <span
                        class="error--text"
                        v-if="scheduleGenerationError !== null"
                      >
                        {{ scheduleGenerationError }}
                      </span>
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="disbursement"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >Anticipated Disbursement</label
                            >
                            <input
                              v-model="
                                form.disbursementDetails
                                  .anticipatedDisbursementDate
                              "
                              type="date"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter number of days"
                              @input="calculateFirstRepaymentDate"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label"
                              >First Repayment Date</label
                            >
                            <input
                              v-model="
                                form.disbursementDetails.firstRepaymentDate
                              "
                              type="date"
                              class="input input--right form__input form__input--lg"
                              placeholder="Enter Arrears Tolerance Amount"
                            />
                            <div
                              class="error--text"
                              v-if="scheduleGenerationError !== null"
                            >
                              {{ scheduleGenerationError }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- SCHEDULE PREVIEW-->
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#schedule"
                    aria-expanded="false"
                    aria-controls="schedule"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Schedule Preview
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div id="schedule" class="collapse" data-parent="#accordion">
                    <div class="pt-5">
                      <div>
                        <div class="form__item">
                          <div class="text-right mt-3">
                            <button
                              class="button button--grey text--600 text--black text--small"
                              @click="generateSchedule"
                              :disabled="loading"
                              type="button"
                            >
                              {{
                                generatingSchedule
                                  ? "Calculating..."
                                  : "Preview Schedule"
                              }}
                            </button>
                          </div>
                        </div>
                        <table
                          class="customtable w-100"
                          v-if="
                            !generatingSchedule &&
                            repayments &&
                            newRepayments &&
                            newRepayments.length
                          "
                        >
                          <thead>
                            <tr>
                              <th
                                class="table__header__text table__text text--capital text--tiny"
                                style="width: 5%"
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Installment Date
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Principal
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Interest
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Fees
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Payment Due
                              </th>
                              <th
                                scope="col"
                                class="table__header__text table__text text--capital text--tiny"
                              >
                                Total balance
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in newRepayments"
                              :key="index"
                            >
                              <td style="word-break: keep-all">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ item.installment }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{
                                    item.installmentDate | moment("DD/MM/YYYY")
                                  }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ toNaira(item.principal) }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ toNaira(item.interest) }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ toNaira(item.fee) }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ toNaira(item.paymentDue) }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--grey"
                                  >{{ toNaira(item.balance) }}</span
                                >
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr>
                              <td class="border-0"></td>
                              <td class="border-0">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--success"
                                >
                                  total
                                </span>
                              </td>
                              <td class="border-0">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--success"
                                >
                                  {{
                                    repayments
                                      ? toNaira(repayments.totalPrincipal)
                                      : "_"
                                  }}
                                </span>
                              </td>
                              <td class="border-0">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--success"
                                >
                                  {{
                                    repayments
                                      ? toNaira(repayments.totalInterest)
                                      : "_"
                                  }}
                                </span>
                              </td>
                              <td class="border-0">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--success"
                                >
                                  {{ repayments ? repayments.totalFee : "_" }}
                                </span>
                              </td>
                              <td class="border-0">
                                <span
                                  class="table__body__text table__text text--capital text--tiny text--success"
                                >
                                  {{
                                    repayments
                                      ? toNaira(repayments.totalPaymentDue)
                                      : "_"
                                  }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!--ACCOUNT NOTES-->
                <div class="modal__accordion">
                  <li
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    data-target="#decriptions"
                    aria-expanded="false"
                    aria-controls="decriptions"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      Account Notes
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    id="decriptions"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="form__item">
                        <textarea
                          cols="5"
                          rows="5"
                          class="input form__input"
                          v-model="form.notes"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item">
                <div class="form__action">
                  <a
                    role="button"
                    data-dismiss="#reFinanceModal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="closeModal"
                  >
                    cancel
                  </a>
                  <button
                    type="button"
                    class="button form__button form__button--lg float-right"
                    @click="confirmSave()"
                    :disabled="instalmentError"
                  >
                    {{ process ? "Processing..." : "Refinance" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    uiProps: {
      productTypeKey: null,
      loanProductType: "Fixed Term Loan",
      loanName: null,
      loanAmount: null,
      errors: {
        interestBalance: { valid: true, message: "" },
        feeBalance: { valid: true, message: "" },
        penaltyBalance: { valid: true, message: "" },
      },
    },
    message: "",
    refinance: false,
    form: {
      guarantors: [],
      outStandingPrincipal: 0,
      topUpAmount: null,
      scheduleSettings: {
        // fixedDaysOfMonth: [],
        gracePeriod: 0,
        // periodicPayment: 0,
        repaymentInstallments: 0,
        repaymentPeriodCount: 0,
        repaymentPeriodUnit: "",
      },
      interestSettings: {
        interestRate: 0,
        interestSpread: 0,
      },
      principalPaymentSettings: {
        amount: 0,
        percentage: 0,
      },
      penaltySettings: {
        penaltyRate: 0,
      },
      accountArrearsSettings: {
        tolerancePercentageOfOutstandingPrincipal: 0,
        tolerancePeriod: 0,
      },
      balances: {
        penaltyBalance: null,
        interestBalance: null,
        feeBalance: null,
      },
      disbursementDetails: {
        anticipatedDisbursementDate: null,
        firstRepaymentDate: null,
      },
      loanAccountId: null,
      loanName: "",
      notes: "",
      productTypeKey: "fixed_term_loan",
    },
    instalmentError: false,
    productFeeList: [],
    loanBalances: {
      feeBalance: 0,
      interestBalance: 0,
      penaltyBalance: 0,
    },
    confirm: false,
    process: false,
    productTypeKey: null,
    whatPage: "customer",
    scheduleReady: false,
    loadingSchedule: false,
    loadingCustomers: false,
    loadingGuarantorAccounts: false,
    generatingSchedule: false,
    guarantorAccounts: null,
    accountHolderKey: null,
    scheduleGenerationError: null,
    repayments: [],
    notes: "",
    loanProduct: null,
    accountDetails: null,
    feeBalanceAmount: "",
    interestBalanceAmt: "",
    penaltyBalanceAmt: "",
    topAmount: "",
  };
}
import { mapState } from "vuex";
import LoanApiService from "@/core/services/api.loan.service";
import { GET_ALL_LOAN_PRODUCTS } from "@/core/services/store/types";
import currencyMixin from "@/mixins/currencyMixin";
import moment from "moment";

export default {
  name: "CloseRefinanceModal",
  mixins: [currencyMixin],
  props: {
    show: Boolean,
    close: Function,
    account: Object,
    customer: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data: function () {
    return initialState();
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.$emit("refresh");
      this.resetWindow();
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    calculateFirstRepaymentDate() {
      const date = this.form.disbursementDetails.anticipatedDisbursementDate;
      const dateNo = this.form.scheduleSettings.repaymentPeriodCount;
      let dated = "";
      if (this.loanInterestType === "Days") {
        dated = moment(date, "YYYY-MM-DD").add("days", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.form.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      if (this.loanInterestType === "Months") {
        dated = moment(date, "YYYY-MM-DD").add("months", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.form.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      dated = moment(date, "YYYY-MM-DD").add("years", dateNo);
      let day = dated.format("DD");
      let month = dated.format("MM");
      let year = dated.format("YYYY");
      this.form.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`;
    },
    validateInstalment() {
      if (
        this.minFirstRepaymentDueDateOffset !== null &&
        this.maxFirstRepaymentDueDateOffset !== null
      ) {
        if (
          this.form.scheduleSettings.repaymentInstallments <
          this.minFirstRepaymentDueDateOffset
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Installment must not be less than ${this.minFirstRepaymentDueDateOffset}`
          );
        }
        if (
          this.form.scheduleSettings.repaymentInstallments >
          this.maxFirstRepaymentDueDateOffset
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Installment must not be greater than ${this.maxFirstRepaymentDueDateOffset}`
          );
        }
      }
      if (this.minFirstRepaymentDueDateOffset !== null) {
        if (
          this.form.scheduleSettings.repaymentInstallments <
          this.minFirstRepaymentDueDateOffset
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Installment must not be less than ${this.minFirstRepaymentDueDateOffset}`
          );
        }
      }
      if (this.maxFirstRepaymentDueDateOffset !== null) {
        if (
          this.form.scheduleSettings.repaymentInstallments >
          this.maxFirstRepaymentDueDateOffset
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Installment must not be greater than ${this.maxFirstRepaymentDueDateOffset}`
          );
        }
      }
      this.instalmentError = false;
    },
    validateToleranceAmount() {
      if (
        this.minToleranceAmount !== null &&
        this.maxToleranceAmount !== null
      ) {
        if (
          this.form.accountArrearsSettings
            .tolerancePercentageOfOutstandingPrincipal < this.minToleranceAmount
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Amount must not be less than ${this.minToleranceAmount}`
          );
        }
        if (
          this.form.accountArrearsSettings
            .tolerancePercentageOfOutstandingPrincipal > this.maxToleranceAmount
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Amount must not be greater than ${this.maxToleranceAmount}`
          );
        }
      }
      if (this.minToleranceAmount !== null) {
        if (
          this.form.accountArrearsSettings
            .tolerancePercentageOfOutstandingPrincipal < this.minToleranceAmount
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Amount must not be less than ${this.minToleranceAmount}`
          );
        }
      }
      if (this.maxToleranceAmount !== null) {
        if (
          this.form.accountArrearsSettings
            .tolerancePercentageOfOutstandingPrincipal > this.maxToleranceAmount
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Amount must not be greater than ${this.maxToleranceAmount}`
          );
        }
      }
      this.instalmentError = false;
    },
    validateTolerancePeriod() {
      if (
        this.minTolerancePeriod !== null &&
        this.maxTolerancePeriod !== null
      ) {
        if (
          this.form.accountArrearsSettings.tolerancePeriod <
          this.minTolerancePeriod
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Period must not be less than ${this.minTolerancePeriod}`
          );
        }
        if (
          this.form.accountArrearsSettings.tolerancePeriod >
          this.maxTolerancePeriod
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Period must not be greater than ${this.maxTolerancePeriod}`
          );
        }
      }
      if (this.minTolerancePeriod !== null) {
        if (
          this.form.accountArrearsSettings.tolerancePeriod <
          this.minTolerancePeriod
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Period must not be less than ${this.minTolerancePeriod}`
          );
        }
      }
      if (this.maxTolerancePeriod !== null) {
        if (
          this.form.accountArrearsSettings.tolerancePeriod >
          this.maxTolerancePeriod
        ) {
          this.instalmentError = true;
          return this.$emit(
            "alertError",
            `Arrears Tolerance Period must not be greater than ${this.maxTolerancePeriod}`
          );
        }
      }
      this.instalmentError = false;
    },
    getLoanProducts() {
      this.$store.dispatch(GET_ALL_LOAN_PRODUCTS);
    },
    resetWindow: function () {
      Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    closeRefinanceModal() {
      this.refinance = false;
      this.process = false;
      this.confirm = false;
    },
    changeSelectedProduct() {
      LoanApiService.get(
        "LoanProduct/getloanproduct/" + this.uiProps.productTypeKey
      )
        .then((res) => {
          this.accountDetails = res.data.data;
        })
        .catch((error) => {
          this.$emit("alertError", error.response.data.message);
        });
    },
    generateSchedule() {
      this.scheduleGenerationError = null;
      if (!this.form.disbursementDetails.firstRepaymentDate) {
        this.scheduleGenerationError = "First Repayment Date is required";
        this.$emit("alertError", "First Repayment Date is required");
        return false;
      }
      const p = {
        loanProductKey: this.uiProps.productTypeKey,
        loanAmount: this.getNewLoanAmounts,
        repaymentInstallments: this.form.scheduleSettings.repaymentInstallments,
        interestRate: this.form.interestSettings.interestRate,
        disbursmentDate:
          this.form.disbursementDetails.anticipatedDisbursementDate,
        firstRepaymentDate: this.form.disbursementDetails.firstRepaymentDate,
        repaymentAreMadeEveryEnum: this.repaymentScheduleMethod,
        repaymentScheduleMethod: this.repaymentScheduleMethod,
        loanAccountProductFeesList: [],
        // gracePeriod: this.account.gracePeriod,
        gracePeriodValue: this.accountDetails.defaultGracePeriod
          ? Number(this.accountDetails.defaultGracePeriod)
          : 0,
      };

      //validate first repayment date
      const self = this;
      this.generatingSchedule = true;
      LoanApiService.patch("LoanAccount/getrepaymentschedule", p)
        .then((res) => res.data)
        .then((res) => {
          self.generatingSchedule = false;
          if (!res.status) {
            self.scheduleGenerationError = res.message;
            self.$refs.disbursement.click();
          } else {
            self.repayments = res.data;
          }
        })
        .catch((e) => {
          self.generatingSchedule = false;
          try {
            if (!e.response.data.status) {
              if (e.response.data.message.indexOf("First repayment") !== -1) {
                self.scheduleGenerationError = e.response.data.message;
                if (self.$refs.disbursement.attributes[2].value == "false") {
                  self.$refs.disbursement.click();
                }
              } else {
                this.$emit("alertError", e.response.data.message);
              }
            }
          } catch (e) {
            this.$emit("alertError", e.message);
          }
        });
    },
    goToLoanDetails(key) {
      const page = this.$router.push({ path: `/loan/accounts/${key}` });
      return this.$router.go(page);
    },
    confirmRefinance() {
      if (this.instalmentError) return;
      let copyOfPayload = JSON.parse(JSON.stringify(this.$data.form));
      copyOfPayload.productTypeKey = this.uiProps.productTypeKey;
      copyOfPayload.outStandingPrincipal =
        this.form.principalPaymentSettings.amount;
      const scheduleSetting = {
        scheduleSettings: copyOfPayload.scheduleSettings,
      };
      delete copyOfPayload.balances;
      const p = {
        loanAccount: scheduleSetting,
        loanAccountKey: copyOfPayload.loanAccountId,
        productTypeKey: copyOfPayload.productTypeKey,
        topUpAmount: copyOfPayload.topUpAmount,
        productFeeList: this.$data.productFeeList,
      };

      // api/LoanAccount/checkloanrefinance
      this.process = true;
      LoanApiService.post("LoanAccount/checkloanrefinance", p)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          this.refinance = false;
          if (res.status) {
            if (res.message?.toLowerCase() !== "proceed") {
              this.message = res.message;
              this.refinance = true;
            } else {
              this.save();
            }
          }
          // if (res.status) {
          //   this.$emit("refresh");
          //   this.$emit("alertSuccess", res.message);
          //   this.closeModal();
          //   this.goToLoanDetails(res.data.loanAccountKey);
          // } else {
          //   this.$emit("alertError", res.message);
          // }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          this.refinance = false;
          try {
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    save: function () {
      if (this.instalmentError) return;
      let copyOfPayload = JSON.parse(JSON.stringify(this.$data.form));
      let copyOfLoanBalances = JSON.parse(
        JSON.stringify(this.$data.loanBalances)
      );
      copyOfPayload.productTypeKey = this.uiProps.productTypeKey;
      copyOfPayload.loanName = this.uiProps.loanName;
      copyOfPayload.loanAmount = this.uiProps.loanAmount;
      copyOfPayload.outStandingPrincipal =
        this.form.principalPaymentSettings.amount;
      copyOfLoanBalances.feeBalance += this.form.balances.feeBalance;
      copyOfLoanBalances.penaltyBalance += this.form.balances.penaltyBalance;
      copyOfLoanBalances.interestBalance += this.form.balances.interestBalance;
      delete copyOfPayload.balances;
      const p = {
        loanAccount: copyOfPayload,
        loanBalances: copyOfLoanBalances,
        topUpAmount: copyOfPayload.topUpAmount,
        outStandingPrincipal: copyOfPayload.outStandingPrincipal,
      };
      this.process = true;
      LoanApiService.post("LoanAccount/loanaccountcloserefinance", p)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("alertSuccess", res.message);
            this.closeModal();
            this.closeRefinanceModal();
            this.goToLoanDetails(res.data.loanAccountKey);
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    setOutStandingBalances(balances) {
      this.form.balances.interestBalance = balances && balances.interestBalance;
      this.interestBalanceAmt = this.form.balances.interestBalance;
      this.form.balances.feeBalance = balances && balances.feesBalance;
      this.feeBalanceAmount = this.form.balances.feeBalance;
      this.form.balances.penaltyBalance = balances && balances.penaltyBalance;
      this.penaltyBalanceAmt = this.form.balances.penaltyBalance;
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    formatAndValidateFeeBalanceAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.balances.feeBalance = amtPaid;
        this.feeBalanceAmount = amountInputted;
        return;
      }
      this.form.balances.feeBalance = this.form.balances.feeBalance;
      this.feeBalanceAmount = "";
    },
    formatAndValidateInterestBalanceAmt(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.balances.interestBalance = amtPaid;
        this.interestBalanceAmt = amountInputted;
        return;
      }
      this.form.balances.interestBalance = this.form.balances.interestBalance;
      this.interestBalanceAmt = "";
    },
    formatAndValidatefPenaltyBalanceAmt(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.balances.penaltyBalance = amtPaid;
        this.penaltyBalanceAmt = amountInputted;
        return;
      }
      this.form.balances.penaltyBalance = this.form.balances.penaltyBalance;
      this.penaltyBalanceAmt = "";
    },
    formatAndValidateTopAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.form.topUpAmount = amtPaid;
        this.topAmount = amountInputted;
        return;
      }
      this.form.topUpAmount = this.form.topUpAmount;
      this.topAmount = "";
    },
    setBalances(balances) {
      let fees = 0;
      let interest = 0;
      let penalty = 0;
      Object.entries(balances).forEach(([key, value]) => {
        if (key.indexOf("fee") !== -1) {
          fees += value;
        } else if (key.indexOf("interest") !== -1) {
          interest += value;
        } else if (key.indexOf("penalty") !== -1) {
          penalty += value;
        }
      });
      this.loanBalances.feeBalance = fees;
      this.loanBalances.interestBalance = interest;
      this.loanBalances.penaltyBalance = penalty;
    },
  },
  watch: {
    show(change) {
      if (change) {
        if (window.location.hash.indexOf("customer") != -1) {
          this.whatPage = "customer";
        }
      }
    },
    "uiProps.productTypeKey": function () {
      const p = this.products.find(
        (item) => item.loanProductKey == this.uiProps.productTypeKey
      );
      if (p) {
        this.loanProduct = p;
        this.interestRate = p.adminInterestProductSettings
          ? p.adminInterestProductSettings.defaultInterestRate
            ? p.adminInterestProductSettings.defaultInterestRate
            : null
          : null;
        this.repaymentScheduleMethod = p.repaymentScheduleMethod
          ? p.repaymentScheduleMethod
          : null;
        this.repaymentInstallments = p.defaultFirstRepaymentDueDateOffset
          ? p.defaultFirstRepaymentDueDateOffset
          : null;
      }
    },
    products(change) {
      if (change) {
        const p = change.find(
          (item) => item.loanProductKey == this.uiProps.productTypeKey
        );
        if (p) {
          this.loanProduct = p;
          this.interestRate = p.adminInterestProductSettings
            ? p.adminInterestProductSettings.defaultInterestRate
              ? p.adminInterestProductSettings.defaultInterestRate
              : null
            : null;
          this.repaymentScheduleMethod = p.repaymentScheduleMethod
            ? p.repaymentScheduleMethod
            : null;
          this.repaymentInstallments = p.defaultFirstRepaymentDueDateOffset
            ? p.defaultFirstRepaymentDueDateOffset
            : null;
        }
        // this.setSelectedProduct(change[0].loanProductKey);
      }
    },
    "disbursementDetails.firstRepaymentDate": function () {
      this.scheduleGenerationError = null;
    },
    "form.balances.interestBalance": function (v) {
      this.interestBalanceAmt = this.inputCurrencyFormat(
        this.form.balances.interestBalance
      );
      this.uiProps.errors.interestBalance.valid = true;
      if (v < 0) {
        this.uiProps.errors.interestBalance.valid = false;
        this.uiProps.errors.interestBalance.message = "Must be positive";
      } else if (v > this.uiProps.loanAmount) {
        this.uiProps.errors.interestBalance.valid = false;
        this.uiProps.errors.interestBalance.message =
          "New amount must be strictly less than the original amount";
      }
    },
    "form.balances.feeBalance": function (v) {
      this.feeBalanceAmount = this.inputCurrencyFormat(
        this.form.balances.feeBalance
      );
      this.uiProps.errors.feeBalance.valid = true;
      if (v < 0) {
        this.uiProps.errors.feeBalance.valid = false;
        this.uiProps.errors.feeBalance.message = "Must be positive";
      } else if (v > this.uiProps.loanAmount) {
        this.uiProps.errors.feeBalance.valid = false;
        this.uiProps.errors.feeBalance.message =
          "New amount must be strictly less than the original amount";
      }
    },
    "form.balances.penaltyBalance": function (v) {
      this.penaltyBalanceAmt = this.inputCurrencyFormat(
        this.form.balances.penaltyBalance
      );
      this.uiProps.errors.penaltyBalance.valid = true;
      if (v < 0) {
        this.uiProps.errors.penaltyBalance.valid = false;
        this.uiProps.errors.penaltyBalance.message = "Must be positive";
      } else if (v > this.uiProps.loanAmount) {
        this.uiProps.errors.penaltyBalance.valid = false;
        this.uiProps.errors.penaltyBalance.message =
          "New amount must be strictly less than the original amount";
      }
    },
    account(change) {
      if (change) {
        if (change.interestSettings) {
          this.uiProps.loanAmount = change.loanAmount;
          this.uiProps.loanName = change.loanName;
          this.form.interestSettings.interestRate = change.interestRate;
          this.form.notes = change.notes;
          this.form.principalPaymentSettings.amount = change.loanBalance;
          this.form.loanAccountId = change.id;
          this.form.scheduleSettings.repaymentPeriodCount =
            change.repaymentPeriodCount;
          this.form.scheduleSettings.repaymentPeriodUnit =
            change.repaymentPeriodUnit;
          // this.form.accountArrearsSettings.arrearsTolerancePeriod =
          //   change.tolerancePeriod;
          // this.form.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          //   change.accountArrearsSettings.tolerance_percentage_of_outstanding_principal;
          this.productFeeList = change.loanProductFees.map((fee) => fee.feeKey);
          this.uiProps.productTypeKey =
            change.adminLoanProduct && change.adminLoanProduct.loan_product_key;
          this.setOutStandingBalances(change.balances);
          this.setBalances(change.balances);
        }
      }
    },
    accountDetails(change) {
      if (change) {
        this.uiProps.loanAmount =
          change.minLoanAmount > change.defaultLoanAmount
            ? change.minLoanAmount
            : change.defaultLoanAmount;
        // this.uiProps.loanName = change.productName;
        // this.uiProps.productTypeKey = change.loanProductKey;
        this.uiProps.loanProductType = change.loanProductType;
        this.form.accountArrearsSettings.tolerancePeriod =
          change.adminArrearsResponseModel.defaultTolerancePeriod;
        this.form.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          change.adminArrearsResponseModel.defaultTolerancePercentageOfOutstandingPrincipal;
        if (change.interestSettings) {
          this.uiProps.loanAmount = change.loanAmount;
          // this.uiProps.loanName = change.loanName;
          // this.form.interestSettings.interestRate = change.interestSettings
          //   ? change.interestSettings.interestRate
          //   : null;
          this.form.notes = change.notes;
          this.form.loanAccountId = change.id;
          this.setOutStandingBalances(change.balances);
          this.setBalances(change.balances);
        }
      }
    },
  },
  computed: {
    ...mapState({
      enums: (state) => state.loan.enums,
      loading: (state) => state.loan.loading_all,
      products: (state) =>
        state.loan.products_all
          ? state.loan.products_all.data
            ? state.loan.products_all.data
            : []
          : [],
      saving: (state) => state.loan.saving,
      loanProductDescription() {
        return this.loanProduct ? this.loanProduct.productDescription : "";
      },
      loanMinInterestRate() {
        return this.account
          ? this.account.adminLoanProduct.administrationInterestProductSetting
            ? this.account.adminLoanProduct.administrationInterestProductSetting
                .min_interest_rate
            : null
          : null;
      },
      loanMaxInterestRate() {
        return this.account
          ? this.account.adminLoanProduct.administrationInterestProductSetting
            ? this.account.adminLoanProduct.administrationInterestProductSetting
                .max_interest_rate
            : null
          : null;
      },
      minFirstRepaymentDueDateOffset() {
        return this.account
          ? this.account.adminLoanProduct
            ? this.account.adminLoanProduct.min_first_repayment_due_date_offset
              ? this.account.adminLoanProduct
                  .min_first_repayment_due_date_offset
              : null
            : null
          : null;
      },
      maxTolerancePeriod() {
        return this.account
          ? this.accountDetails.adminArrearsResponseModel
            ? this.accountDetails.adminArrearsResponseModel.maxTolerancePeriod
            : 0
          : 0;
      },
      minTolerancePeriod() {
        return this.accountDetails
          ? this.accountDetails.adminArrearsResponseModel
            ? this.accountDetails.adminArrearsResponseModel.minTolerancePeriod
            : 0
          : 0;
      },
      maxToleranceAmount() {
        return this.account
          ? this.accountDetails.adminArrearsResponseModel
            ? this.accountDetails.adminArrearsResponseModel
                .maxTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
      },
      minToleranceAmount() {
        return this.account
          ? this.accountDetails.adminArrearsResponseModel
            ? this.accountDetails.adminArrearsResponseModel
                .minTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
      },
      maxFirstRepaymentDueDateOffset() {
        return this.account
          ? this.account.adminLoanProduct.max_first_repayment_due_date_offset
            ? this.account.adminLoanProduct.max_first_repayment_due_date_offset
            : null
          : null;
      },
      maxTolerancePercentageOfOutsatndingPrincipal() {
        return this.loanProduct
          ? this.loanProduct.adminArrearsResponseModel
            ? this.loanProduct.maxTolerancePercentageOfOutsatndingPrincipal
            : null
          : null;
      },
      minTolerancePercentageOfOutsatndingPrincipal() {
        return this.loanProduct
          ? this.loanProduct.adminArrearsResponseModel
            ? this.loanProduct.minTolerancePercentageOfOutsatndingPrincipal
            : null
          : null;
      },
      loanInterestType(state) {
        if (this.account) {
          if (this.account.adminLoanProduct) {
            const hh = state.loan.enums.repaymentAreMadeEvery.find(
              (h) =>
                h.id == this.account.adminLoanProduct.repayment_schedule_method
            );
            return hh.name;
          }
        }
      },
      loanInterestCalculationMethod(state) {
        if (this.loanProduct) {
          const hh = state.loan.enums.interestCalculationMethod.find(
            (h) => h.id == this.loanProduct.interestCalculationMethod
          );
          return hh.name;
        }
      },
    }),
    accountRecipient() {
      return this.account
        ? this.account.customerInformationDetails
          ? this.account.customerInformationDetails.data.customerFirstName +
            " " +
            this.account.customerInformationDetails.data.customerLastName
          : ""
        : "";
    },
    newRepayments() {
      if (this.repayments.repayments) {
        const newRep = this.repayments.repayments;
        newRep.shift();
        return this.repayments.repayments;
      }
      return null;
    },
    getNewLoanAmounts() {
      return this.form.topUpAmount + this.form.principalPaymentSettings.amount;
      // return (
      //   this.form.topUpAmount +
      //   this.form.principalPaymentSettings.amount +
      //   this.form.balances.penaltyBalance +
      //   this.form.balances.feeBalance +
      //   this.form.balances.interestBalance
      // );
    },
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.getLoanProducts();
        this.accountDetails = this.account;
        // this.uiProps.productTypeKey = this.$route.params.loanAccountKey;
        this.interestBalanceAmt = this.form.balances.interestBalance;
        this.interestBalance = this.form.balances.interestBalance;
      }
    });
  },
};
</script>
