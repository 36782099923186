<template>
  <div class="page__head d-flex">
    <div
      class="page__head__container ml-auto align-items-center justify-content-between"
    >
      <div class="page__head__actions">
        <div class="d-flex">
          <button
            type="button"
            v-if="
              shouldDisplay(['ACTIVE', 'Active', 'ACTIVE_IN_ARREARS']) &&
              !accountIsLocked
            "
            class="mx-2 button page__body__header__button button--grey text--capital"
            @click="openRepayment"
          >
            <span class="d-inline-flex mr-2">enter repayment</span>
          </button>

          <button
            type="button"
            v-if="data.accountState.toLowerCase() === 'partial application'"
            class="mx-2 button page__body__header__button button--grey text--capital"
            @click="moveLoanToPending"
            :disabled="isPendingState"
          >
            <span class="d-inline-flex mr-2">Submit</span>
          </button>

          <div
            class="align-self-center mx-2 dropdown"
            v-if="
              (shouldDisplay([
                'ACTIVE',
                'Active',
                'ACTIVE IN ARREARS',
                'CLOSED WRITEN_OFF',
                'CLOSED RESCHEDULED',
                'CLOSED REJECTED',
                'Partial Application',
                'PARTIAL APPLICATION',
                'PENDING APPROVAL',
                'Pending Approval',
                'APPROVED',
              ]) &&
                userCanCloseLoanAccount) ||
              (accountIsLocked &&
                data.accountState.toLowerCase() !== 'pending approval')
            "
          >
            <button
              class="button button--grey text--capital text--small d-flex align-items-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              close
              <span class="dropdown__icon">
                <ion-icon name="caret-down-outline"></ion-icon>
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item text--capital"
                data-modal="#payOffModal"
                v-if="shouldDisplay(['ACTIVE', 'Active', 'ACTIVE IN ARREARS'])"
                @click="payOffModal"
                >Pay Off</a
              >
              <a
                class="dropdown-item text--capital"
                data-modal="#reFinanceModal"
                v-if="
                  shouldDisplay(['ACTIVE', 'Active', 'ACTIVE IN ARREARS']) &&
                  userCanRefinance
                "
                @click="openRefinance"
                >Refinance</a
              >
              <a
                class="dropdown-item text--capital"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'CLOSED RESCHEDULED',
                  ]) && userCanReschedule
                "
                @click="openReschedule"
                >Reschedule</a
              >
              <a
                class="dropdown-item text--capital"
                data-modal="#writeOffModal"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'CLOSED WRITEN OFF',
                  ]) && userCanWriteOffLoanAccount
                "
                @click="openWriteOff"
                >Write Off</a
              >
              <a
                class="dropdown-item text--capital"
                v-if="
                  shouldDisplay([
                    'PARTIAL APPLICATION',
                    'Partial Application',
                    'PENDING APPROVAL',
                    'Pending Approval',
                  ]) &&
                  !accountIsLocked &&
                  userCanWithdraw
                "
                @click="openWithdraw"
                >Withdraw</a
              >
            </div>
          </div>
          <div class="align-self-center mx-2 dropdown">
            <button
              class="button button--grey text--capital text--small d-flex align-items-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="
                shouldDisplay([
                  'ACTIVE',
                  'Active',
                  'ACTIVE IN ARREARS',
                  'CLOSED',
                ]) && userCanViewReports
              "
            >
              report
              <span class="dropdown__icon">
                <ion-icon name="caret-down-outline"></ion-icon>
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item text--capital"
                @click="showAccountStatementModal"
                >Account Statement</a
              >
              <a
                v-if="
                  data.accountState.toLowerCase() == 'closed' &&
                  !accountIsLocked &&
                  userCanCreateReports
                "
                class="dropdown-item text--capital"
                data-toggle="modal"
                data-target="#activityModal"
                data-backdrop="static"
                >Generate Letter of Non-Indebtedness</a
              >
            </div>
          </div>
          <div class="align-self-center mx-2 dropdown" v-if="!accountIsPaidOff">
            <button
              class="button button--grey text--capital text--small d-flex align-items-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
            >
              more
              <span class="dropdown__icon">
                <ion-icon name="caret-down-outline"></ion-icon>
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item text--capital"
                v-if="
                  shouldDisplay(['ACTIVE', 'ACTIVE IN ARREARS']) &&
                  userCanApplyFees &&
                  !accountIsLocked
                "
                @click="applyFees"
                >Apply Fee</a
              >
              <a
                class="dropdown-item text--capital"
                data-modal="#accruedInterestModal"
                v-if="
                  shouldDisplay(['ACTIVE', 'ACTIVE IN ARREARS']) &&
                  userCanAccruedInterest &&
                  !accountIsLocked
                "
                @click="appliedAccruedModal"
                >Apply Accrued Interest</a
              >
              <!-- <a
                class="dropdown-item text--capital"
                data-toggle="modal"
                data-target="#offerLetter"
                data-backdrop="static"
                @click="getEmail"
                v-if="userCanSendOfferLetter"
              >
                Send Offer Letter
              </a> -->
              <!-- <a class="dropdown-item text--capital">Apply Payment Holidays</a> -->
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item text--capital"
                data-modal="#addLoanAccountFieldModal"
                v-if="
                  userCanCreateLoanAccount &&
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'CLOSED',
                    'CLOSED WRITEN OFF',
                    'CLOSED RESCHEDULED',
                    'CLOSED REJECTED',
                    'Pending Approval',
                    'PENDING APPROVAL',
                    'PARTIAL APPLICATION',
                    'Partial Application',
                  ]) &&
                  !accountIsLocked
                "
                @click="addFeeModal"
                >Add Field</a
              >
              <a
                class="dropdown-item text--capital"
                @click="editAccount()"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'CLOSED',
                    'CLOSED WRITEN OFF',
                    'CLOSED RESCHEDULED',
                    'CLOSED REJECTED',
                    'PARTIAL APPLICATION',
                    'Partial Application',
                    'APPROVED',
                    'Pending Approval',
                    'PENDING APPROVAL',
                    'PENDING DISBURSEMENT',
                    '',
                  ]) &&
                  userCanEditLoanAccount &&
                  !accountIsLocked
                "
                >Edit Account</a
              >
              <a
                class="dropdown-item text--capital"
                data-modal="#editSecurity"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'PARTIAL APPLICATION',
                    'Partial Application',
                  ]) &&
                  shouldShowSecurities &&
                  !accountIsLocked
                "
                @click="$emit('show-security')"
                >Edit Securities</a
              >
              <a
                class="dropdown-item text--capital"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'Active',
                    'ACTIVE IN ARREARS',
                    'PARTIAL APPLICATION',
                    'Partial Application',
                    'Pending Approval',
                    'PENDING APPROVAL',
                    'PENDING DISBURSEMENT',
                  ]) && !accountIsLocked
                "
                @click="openSchedule"
                >Edit Schedule</a
              >
              <a
                class="dropdown-item text--capital"
                v-if="!accountIsLocked"
                @click="editPenalty"
                >Edit Penalty Rate</a
              >
              <div class="dropdown-divider"></div>
              <!-- <a
                class="dropdown-item text--capital"
                data-modal="#recommendDepositModal"
                >Set Recommended Deposit</a
              > -->
              <!-- <a
                class="dropdown-item text--capital"
                data-modal="#changeHistoryModal"
                v-if="
                  shouldDisplay([
                    'ACTIVE',
                    'ACTIVE IN ARREARS',
                    'PARTIAL APPLICATION',
                    'Partial Application',
                    'PENDING APPROVAL',
                    'PENDING DISBURSEMENT',
                    'CLOSED WRITEN OFF',
                    'APPROVED',
                  ])
                "
                >View Change History</a
              > -->
              <a
                class="dropdown-item text--capital"
                data-modal="#unLockAccountModal"
                v-if="shouldDisplay(['Locked']) || accountIsLocked"
                @click="unlockAccount"
                >UnLock Account</a
              >
              <a
                class="dropdown-item text--capital"
                data-modal="#lockAccountModal"
                @click="lockAccount"
                v-if="
                  shouldDisplay(['ACTIVE', 'Active', 'ACTIVE IN ARREARS']) &&
                  !accountIsLocked &&
                  userCanLockLoanAccounts
                "
                >Lock Account</a
              >
              <a
                class="dropdown-item text--capital"
                v-if="accountIsWrittenOff"
                @click="undoClosure"
              >
                Undo Closure
              </a>
            </div>
          </div>
          <!-- <button
            type="button"
            class="mx-2 button page__body__header__button button--grey text--capital"
          >
            <img src="assets/img/printer.svg" alt="" srcset="" />
          </button> -->
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="activityModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="activityModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <div style="width: 90%; text-align: center">
                <h5
                  class="modal__head__title text--capital text--center"
                  id="activityModalLabel"
                >
                  Letter of Non-Indebtedness
                </h5>
              </div>

              <div style="width: 8%">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span style="font-size: 2.5rem" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <!-- <div class="container-fluid">

            </div> -->
            <div
              ref="indebtedness"
              id="letter"
              style="overflow: hidden; width: 100%"
            >
              <div style="position: relative">
                <img
                  alt="template-header"
                  src="@/assets/img/template_header.png"
                  style="width: 100%"
                />
                <span
                  style="
                    position: absolute;
                    color: white;
                    left: 760px;
                    top: 30px;
                  "
                >
                  <h1 style="font-size: 2.5em; font-weight: 700">
                    {{ getLetter.dateOfGenerationOfLetter | moment }}
                  </h1>
                </span>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 60px;
                  padding-left: 69px;
                  width: 100%;
                "
              >
                <h1 style="font-weight: bolder">Dear Sir/Ma,</h1>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 60px;
                  padding-left: 69px;
                  width: 100%;
                  justify-content: center;
                  margin-bottom: 20px;
                "
              >
                <h1 style="font-weight: bolder">
                  LETTER OF NON-INDEBTEDNESS -
                  {{
                    data?.customerInformationDetails?.data?.customerFirstName
                  }}
                  {{ " " }}
                  {{ data?.customerInformationDetails?.data?.customerLastName }}
                </h1>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-top: 40px;
                  padding-left: 69px;
                  width: 100%;
                  font-size: 20px;
                "
              >
                <div style="font-size: 20px; margin-bottom: 35px">
                  The above subject matter refers.
                </div>

                <div style="font-size: 20px; margin-bottom: 35px">
                  This serves to conﬁrm that on
                  {{ getLetter.closedDate | moment }}, you fully liquidated the
                  sum of NGN{{ getLetter.loanAmount + ".00" }} which was
                  disbursed to you on {{ getLetter.disbursementDate | moment }}.
                  Therefore, you are not indebted to Limited as at the date of
                  this letter.
                </div>

                <div style="font-size: 20px; margin-bottom: 35px">
                  The above information is strictly conﬁdential and is provided
                  without any liability on the part of the Company and/or any of
                  its staff or agent.
                </div>

                <div style="font-size: 20px; margin-bottom: 35px">
                  Thank you.
                </div>

                <div style="font-size: 20px; margin-bottom: 35px">
                  Yours Faithfully,
                </div>

                <div style="font-size: 20px; margin-bottom: 35px">
                  For: Management System
                </div>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 60px;
                  padding-left: 69px;
                  width: 100%;
                "
              >
                <div style="width: 50%">
                  <hr style="border: 1.5px solid black; width: 50%" />
                  <div style="font-size: 20px">
                    Michael Emetoh<br />
                    Head, Risk Management
                  </div>
                </div>
                <div style="width: 50%">
                  <hr style="border: 1.5px solid black; width: 50%" />
                  <div style="font-size: 20px">
                    Michael Emetoh<br />
                    Head, Risk Management
                  </div>
                </div>
              </div>

              <div style="position: relative; padding-top: 60px">
                <img
                  alt="template-footer"
                  src="@/assets/img/template_footer.png"
                  style="width: 100%"
                />
                <!-- <span style="position:absolute; color:white; width:100%; left:278px;top:30px">
                  <h5 style="">Please address all enquiries to  Finance Limited, 177A, Ligali Ayorinde Street, Victoria Island, Lagos,</h5>
                  <h5>Printed on: 29 December, 2021</h5>
                </span> -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              id="closeModal"
              class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
              style="height: 44px"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              data-dismiss="#confirmModal"
              class="button form__button form__button--md save-changes fitrem"
              v-if="isLoading"
            >
              <div class="spinner-border texxt-light" role="status"></div>
            </button>
            <button
              type="button"
              v-else
              class="button form__button form__button--sm save-changes modal__confirm__button--save fitrem"
              @click="downloadIndebtedness"
            >
              Download Letter
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="offerLetter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="offerLetterModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <div style="width: 90%; text-align: center">
                <h5
                  class="modal__head__title text--capital text--center"
                  id="offerLetterModalLabel"
                >
                  Offer Letter
                </h5>
              </div>

              <div style="width: 8%">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeLetterModal"
                >
                  <span style="font-size: 2.5rem" aria-hidden="true">
                    &times;
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div
              ref="offerletter"
              id="offletter"
              style="overflow: hidden; width: 100%"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 5px 20px;
                "
              >
                <img
                  alt="template-header"
                  src="@/assets/img/royalbank.png"
                  style="width: 30%"
                />
                <hr
                  style="
                    border: 2px solid #000080;
                    width: 70%;
                    margin-top: 50px;
                  "
                />
              </div>

              <div
                style="
                  display: block;
                  padding-top: 20px;
                  padding-left: 30px;
                  width: 100%;
                "
                class="text--bold"
              >
                <!-- <h3 style="font-weight: bold">REMFB/SJO/VOL.7</h3> -->
                <p style="font-size: 13px">{{ currentDate }}</p>
                <h3 style="font-weight: bold">
                  {{
                    data?.customerInformationDetails?.data?.customerFirstName ||
                    ""
                  }}
                  {{ " " }}
                  {{
                    data?.customerInformationDetails?.data
                      ?.customerMiddleName || ""
                  }}
                  {{ " " }}
                  {{
                    data?.customerInformationDetails?.data?.customerLastName ||
                    ""
                  }}
                </h3>
                <p style="font-size: 13px">
                  {{
                    data?.customerInformationDetails?.data
                      ?.streetAddressLine2 || ""
                  }}
                </p>
                <h3 style="font-weight: bold">Dear Sir/Ma,</h3>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 30px;
                  padding-left: 30px;
                  width: 100%;
                  justify-content: center;
                  margin-bottom: 10px;
                "
                class="text--bold"
              >
                <h3 style="font-weight: bold; border-bottom: 1px solid black">
                  OFFER OF SHORT TERM LOAN FACILITY
                </h3>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-top: 5px;
                  padding-left: 30px;
                  width: 100%;
                "
                class="table__text"
              >
                <div style="font-size: 13px; margin-bottom: 10px; width: 90%">
                  With reference to your application dated
                  {{ data.creationDate | moment }}, we hereby write to inform
                  you that the Management has considered and approved your
                  request under the following terms and conditions:
                </div>

                <div
                  style="
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-top: 10px;
                  "
                >
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      BORROWER:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        data?.customerInformationDetails?.data
                          ?.customerFirstName || ""
                      }}
                      {{ " " }}
                      {{
                        data?.customerInformationDetails?.data
                          ?.customerMiddleName || ""
                      }}
                      {{ " " }}
                      {{
                        data?.customerInformationDetails?.data
                          ?.customerLastName || ""
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      RESIDENTIAL ADDRESS:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        data?.customerInformationDetails?.data
                          ?.streetAddressLine2 || ""
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      BUSINESS ADDRESS:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        data?.customerInformationDetails?.data
                          ?.streetAddressLine1 || ""
                      }}
                    </p>
                  </div>
                  <!-- <div style="display:flex; justify-content: start; align-items: center; padding: 5px 0 5px 0">
                    <h3 style="font-size: 13px; font-weight: bold">GENERAL NATURE OF BUSINESS:</h3>
                    <p style="font-size: 13px; padding-left: 40px"></p>
                  </div> -->
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      TYPE OF CREDIT:
                    </h3>
                    <p style="font-size: 13px">
                      {{ data.loanName }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">PURPOSE:</h3>
                    <p style="font-size: 13px">{{ data.notes }}</p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">AMOUNT:</h3>
                    <p style="font-size: 13px">
                      ₦{{ addCommas(data.loanAmount) }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">TENURE:</h3>
                    <p style="font-size: 13px">
                      {{
                        getTenure(
                          data.repaymentInstallments,
                          data.adminLoanProduct.repayment_schedule_method
                        )
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      EFFECTIVE DATE:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        data.anticipatedDisbursementDetails
                          .anticipatedDisbursementDate | moment
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      EXPIRY DATE:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        getExpireDate(
                          data.anticipatedDisbursementDetails
                            .anticipatedDisbursementDate,
                          data.repaymentInstallments,
                          data.adminLoanProduct.repayment_schedule_method
                        ) | moment
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      REPAYMENT SOURCE:
                    </h3>
                    <p style="font-size: 13px">
                      {{
                        data.anticipatedDisbursementDetails
                          .anticipatedBankAccountNumber
                      }}, {{ " "
                      }}{{
                        data.anticipatedDisbursementDetails.anticipatedBankName
                      }}
                    </p>
                  </div>
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      MONTHLY REPAYMENT:
                    </h3>
                    <p style="font-size: 13px">
                      ₦{{ addCommas(getMonthlyRepayment) }}
                    </p>
                  </div>
                </div>

                <div
                  style="
                    display: flex;
                    padding-top: 20px;
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 5px;
                  "
                >
                  <h3
                    style="font-weight: bold; border-bottom: 1px solid black"
                    class="text--bold"
                  >
                    Pricing
                  </h3>
                </div>

                <div style="display: flex; width: 100%; flex-direction: column">
                  <div class="grid--items__cus">
                    <h3 style="font-size: 13px; font-weight: bold">
                      INTEREST RATE:
                    </h3>
                    <p style="font-size: 13px; padding-left: 40px">
                      {{ data.interestRate }}% flat per month.
                    </p>
                  </div>
                  <div style="padding: 3px 0 3px 0" class="grid--items">
                    <div v-for="(fee, i) in getFeesValues" :key="i">
                      <h3
                        style="
                          font-size: 13px;
                          color: black;
                          font-weight: bold !important;
                        "
                      >
                        {{ fee.fieldName }}:
                      </h3>
                      <p style="font-size: 13px">
                        {{ fee.value }}
                      </p>
                    </div>
                  </div>
                  <div
                    id="second"
                    style="
                      display: flex;
                      flex-direction: column;
                      padding-top: 20px;
                    "
                  >
                    <h3 style="font-size: 13px; font-weight: bold">
                      COLLATERAL SECURITY/CONDITIONS PRECEDENT TO DRAW DOWN:
                    </h3>
                    <span style="padding-left: 50px">
                      <ul style="list-style-type: square; font-size: 13px">
                        <li>
                          Acceptance of the terms and conditions of the offer as
                          contained herein.
                        </li>
                        <li>
                          Payment of our fees which are non-refundable.<br />
                          This facility shall be guaranteed by
                          {{ getGuarantorsValues.guarantor_name_1 }} of
                          {{ getGuarantorsValues.guarantor_address_1 }}. (friend
                          to the applicant).<br />

                          {{
                            getGuarantorsValues?.guarantor_name_2 ||
                            getGuarantorsValues?.guarantor_address_2
                              ? "This facility shall equally be guaranteed by " +
                                getGuarantorsValues?.guarantor_name_2 +
                                " of " +
                                getGuarantorsValues?.guarantor_address_2 +
                                ". (friend to the applicant)."
                              : ""
                          }}
                        </li>
                        <li>
                          Execution of Transfer of ownership /Right Entry form
                          through sworn Affidavit.
                        </li>
                        <li>
                          Repayment on facility shall be on monthly basis.
                        </li>
                      </ul>
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <h3 style="font-size: 13px; font-weight: bold">
                      DEFAULT CLAUSE:
                    </h3>
                    <span style="padding: 0px 0 15px 30px; font-size: 13px">
                      <p style="font-size: 13px; font-weight: bold">
                        In event of occurrence of any of the following REMFB may
                        terminate the facility:
                      </p>
                      <p style="font-size: 13px; padding-left: 40px">
                        a) If borrower(s) stops, suspends or threatened to stop
                        or suspend its payment without communicating its
                        inability to service the facility.
                      </p>
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <h3 style="font-size: 13px; font-weight: bold">
                      REPAYMENT CLAUSE:
                    </h3>
                    <span style="padding: 0px 0 15px 30px; font-size: 13px">
                      If the borrower intends to liquidate the facility before
                      the end of the loan period the borrower shall pay up all
                      the balance outstanding and a penalty charge of
                      {{ data?.payOffRate }}% on the amount outstanding.<br /><br />
                      Where the borrower(s) fail to honour his/her obligation on
                      due date, the lender can offset the outstanding loan
                      amount with the accrued interest charges from any funds
                      that the borrower may have in Nigerian bank using his/her
                      BVN number and through global standing instruction (GSI).
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <h3 style="font-size: 13px; font-weight: bold">
                      OTHER CONDITIONS:
                    </h3>
                    <span style="padding-left: 50px">
                      <ol style="list-style-type: lower-roman; font-size: 13px">
                        <li>
                          Agreement to pay on demand such sum as may be
                          necessary to reimburse us for any out of pocket
                          expenses (legal or otherwise) that may be incurred by
                          us in receiving or attempting to recover any amount
                          due to us.
                        </li>
                        <li>
                          Where the borrower(s) fails to honour his/her
                          obligation on due date, the lender can offset the
                          outstanding loan amount with the accrued interest
                          charges from any funds that the borrower may have in
                          Nigerian bank using his/her BVN number and through
                          Global Standing Instruction (GSI). Where the Borrower
                          defaults REMFB reserves the right to offset the
                          outstanding Loan amount through setting up an
                          irrevocable standing order with your BVN on APPZONE
                          RECOVA. Returned cheque shall attract a penalty charge
                          N5,000.00 or 5% flat of the face value whichever is
                          higher.
                        </li>
                        <li>
                          REMFB may collect, use and disclose my
                          transaction/information to the appointed Credit
                          Bureaus and that the Credit bureaus may use the
                          information for any approved business purposes as may
                          from time to time be prescribed by the CBN and/or any
                          relevant statue.
                        </li>
                        <li>
                          REMFB reserves the right to call-in this facility at
                          anytime without recourse to the borrower.
                        </li>
                      </ol>
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <h3 style="font-size: 13px; font-weight: bold">WAIVER:</h3>
                    <span style="padding: 0px 0 15px 30px; font-size: 13px">
                      Failure or delay by us in exercising any remedy, power or
                      right as lender as contained in this offer letter shall
                      not be considered as waiver or impairment thereof nor
                      shall it affect or impair any such remedies, power or
                      rights in respect of any defaults.
                    </span>
                  </div>

                  <div style="display: flex; flex-direction: column">
                    <h3 style="font-size: 13px; font-weight: bold">
                      OFFER VALIDITY:
                    </h3>
                    <span style="padding: 0px 0 15px 30px; font-size: 13px">
                      The offer becomes invalid in the event of the borrower not
                      executing this offer within the next 7 days after
                      acceptance. Yours faithfully,
                    </span>
                    <label
                      style="font-size: 13px; color: black; font-weight: bold"
                    >
                      For: Royal Exchange Microfinance Bank Ltd.
                    </label>
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 20px;
                  padding-left: 30px;
                  width: 100%;
                "
                class="table__text"
              >
                <div style="width: 50%">
                  <img
                    alt="signature1"
                    src="@/assets/img/signature1.png"
                    style="width: 30%"
                  />
                  <hr
                    style="border: 1px solid black; width: 50%; margin-top: 0"
                  />
                  <div style="font-size: 13px; color: black; font-weight: bold">
                    Onwuka Blessing (Mrs.)<br />
                    Head. Credit Admin. and Loan Monitoring
                  </div>
                </div>
                <div style="width: 50%">
                  <img
                    alt="signature2"
                    src="@/assets/img/signature2.png"
                    style="width: 40%; height: 50%"
                  />
                  <hr style="border: 1px solid black; width: 50%" />
                  <div style="font-size: 13px; color: black; font-weight: bold">
                    Abiodun Adegoke (Mr.)<br />
                    Executive Director
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-top: 20px;
                  padding-left: 30px;
                  width: 100%;
                "
                class="table__text"
                id="third"
              >
                <div
                  style="
                    display: flex;
                    padding-top: 20px;
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                  "
                  class="text--bold"
                >
                  <h3 style="font-weight: bold; border-bottom: 1px solid black">
                    MEMORANDUM OF ACCEPTANCE
                  </h3>
                </div>
                <span style="font-size: 13px; color: black; font-weight: bold">
                  I------------------------------------------------------------------------------------------------------------------------------<br /><br />
                  of---------------------------------------------------------------------------------------------------------------------------<br /><br />
                  -------------------------------------------------------------------------------------------------------------------------------<br /><br />
                  Hereby, unconditionally accept your offer
                  reference----------------------------------- on this
                  -------<br /><br />
                  day of ----------------------- {{ currentYear }}.
                </span>
              </div>

              <div
                style="
                  display: flex;
                  padding-top: 50px;
                  padding-left: 30px;
                  width: 100%;
                "
                class="table__text"
              >
                <div style="width: 50%">
                  <hr style="border: 1px solid black; width: 50%" />
                  <div style="font-size: 15px; color: black; font-weight: bold">
                    NAME
                  </div>
                </div>
                <div style="width: 50%">
                  <hr style="border: 1px solid black; width: 50%" />
                  <div style="font-size: 15px; color: black; font-weight: bold">
                    SIGNATURE
                  </div>
                </div>
              </div>

              <div style="padding-top: 30px; width: 100%" class="table__text">
                <span style="color: blue; font-size: 10px; width: 80%">
                  Royal Exchange Microfinance Bank Limited RC: 828604 I
                  Registered office: 7 OLUSOJI IDOWU STREET, ILUPEJU,
                  LAGOS-NIGERIA I Website: www.royalexchangemfb.com I Email:
                  hello@royalbankng.com I Phone: +234-915-269-0402
                </span>
                <div
                  style="width: 100%; height: 50px; background: #0055ff"
                ></div>
              </div>
            </div>
          </div>
          <hr v-if="showEmailField" />
          <div
            style="margin-left: 25px !important"
            v-if="showEmailField"
            class="table__text"
          >
            <p
              class="form__label"
              style="font-size: 12px; color: red !important"
            >
              This customer does not have an email address, you need to add a
              valid email address to send this letter to the customer.
            </p>
          </div>
          <div class="m-5" style="width: 50%" v-if="showEmailField">
            <label class="form__label text--black text--capital mb-3"
              >Enter Email</label
            >
            <input
              type="text"
              class="input form__input form__input--lg r-font"
              placeholder="Enter email"
              v-model="email"
              @input="validateEmailCustomer(email)"
            />
            <p class="text--danger" v-if="isValidEmail">
              Please provide a valid email
            </p>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              id="closeModal"
              class="
                button button--md
                modal__confirm__button
                modal__confirm__button--cancel
                mr-2
              "
              style="height: 44px;"
              data-dismiss="modal"
            >
              Close
            </button> -->
            <button
              data-dismiss="#confirmModal"
              class="button form__button form__button--md save-changes fitrem"
              v-if="isLoading"
            >
              <div class="spinner-border texxt-light" role="status"></div>
            </button>
            <button
              type="button"
              v-else
              class="button form__button form__button--sm save-changes modal__confirm__button--save fitrem"
              @click="downloadOfferLetter(true)"
              :disabled="isValidEmail"
            >
              send
            </button>

            <button
              type="button"
              class="button form__button form__button--sm save-changes modal__confirm__button--save fitrem"
              @click="downloadOfferLetter(false)"
              :disabled="isDownloading || isLoading"
            >
              {{ isDownloading ? "please wait..." : "Download" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <accountStatement
      :show="showAccountStatement"
      :loanKey="loanKey"
      @close="showAccountStatementModal"
      @statementAlert="statementAlert"
    />
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.loan.service";
import accountStatement from "./account_statement_template.vue";
import moment from "moment";
export default {
  name: "LoanAccountDetailsHeading",
  components: {
    accountStatement,
  },
  data() {
    return {
      showAccountStatement: false,
      getLetter: "",
      isLoading: false,
      isDownloading: false,
      showEmailField: false,
      isValidEmail: false,
      email: null,
      currentDate: moment().format("DD MMMM, YYYY"),
      currentYear: moment().format("YYYY"),
    };
  },
  methods: {
    statementAlert({ type, message }) {
      this.$emit("showAlert", { type, message });
    },
    editAccount() {
      this.$emit("editAccount");
    },
    showAccountStatementModal() {
      this.showAccountStatement = !this.showAccountStatement;
    },
    shouldDisplay(access) {
      if (access.indexOf(this.status) > -1) {
        return true;
      } else {
        return false;
      }
    },
    moveLoanToPending() {
      this.$emit("move-loan-to-pending");
    },
    openSchedule() {
      this.$emit("loadSchedule", this.loanKey);
      this.$emit("open-edit-schedule");
    },
    applyFees() {
      this.$emit("open-add-fee");
    },
    openRepayment() {
      this.$emit("open-repayment");
    },
    payOffModal() {
      this.$emit("open-payoff-modal");
    },
    appliedAccruedModal() {
      this.$emit("open-applied-modal");
    },
    addFeeModal() {
      this.$emit("open-add-field");
    },
    editPenalty() {
      this.$emit("edit-penalty-modal");
    },
    lockAccount() {
      this.$emit("lock-account");
    },
    unlockAccount() {
      this.$emit("unlock-account");
    },
    openRefinance() {
      this.$emit("refinance-modal");
    },
    openReschedule() {
      this.$emit("reschedule-modal");
    },
    openWriteOff() {
      this.$emit("writeoff-modal");
    },
    undoClosure() {
      this.removeClosure();
    },
    removeClosure() {
      this.$emit("undo-closure");
    },
    openWithdraw() {
      this.$emit("withdraw-loan");
    },
    addCommas(amount) {
      if (amount) {
        amount = Number(amount);
        let fixedAmount = amount.toFixed(2);
        return fixedAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        return "0.00";
      }
    },
    getEmail() {
      this.$emit("loadSchedule", this.loanKey);
      if (this.data?.customerInformationDetails?.data?.customerEmailAddress) {
        this.showEmailField = false;
        this.isValidEmail = false;
        return;
      }
      this.showEmailField = true;
      this.isValidEmail = true;
    },
    validateEmailCustomer(email) {
      let validRegex1 = /\S+@\S+\.\S+/;
      if (!email.trim().match(validRegex1)) {
        this.isValidEmail = true;
        return;
      }
      this.isValidEmail = false;
    },
    downloadOfferLetter(isSendEmail) {
      if (!isSendEmail) {
        this.isDownloading = true;
      } else {
        this.isLoading = true;
      }
      // var htmlWidth = 210;
      // var htmlHeight = 295;
      const second = document.getElementById("second");
      second.style.paddingTop = "500px";
      const third = document.getElementById("third");
      third.style.paddingTop = "300px";
      var htmlWidth = 190;
      var htmlHeight = 750;
      var pdfWidth = htmlWidth + 15 * 1;
      var pdfHeight = pdfWidth * 1.5 + 15 * 1;

      var doc = new jsPDF("p", "mm");
      var pageCount = Math.ceil(htmlHeight / pdfHeight) - 1;

      html2canvas(this.$refs.offerletter, { allowTaint: true }).then(
        (canvas) => {
          canvas.getContext("2d");
          const img = canvas.toDataURL("image/png");
          doc.addImage(img, "PNG", 15, 15, htmlWidth, htmlHeight);

          for (var i = 1; i <= pageCount; i++) {
            doc.addPage();
            doc.addImage(
              img,
              "PNG",
              15,
              -(pdfHeight * i) + 15,
              htmlWidth,
              htmlHeight
            );
          }
          second.style.paddingTop = "20px";
          third.style.paddingTop = "20px";

          if (isSendEmail) {
            this.sendEmail(doc.output("datauristring"));
          } else {
            doc.save("offer_letter.pdf");
            setTimeout(() => {
              this.isDownloading = false;
            }, 1500);
            document.getElementById("closeLetterModal").click();
            this.$emit("close");
          }
        }
      );
    },
    sendEmail(file) {
      const fileData = this.dataURLtoFile(file, "offer_letter.pdf");
      const myEmail = this.data?.customerInformationDetails?.data
        ?.customerEmailAddress
        ? this.data?.customerInformationDetails?.data?.customerEmailAddress
        : this.email;

      let formData = new FormData();
      formData.append("file", fileData);

      ApiService.postFormData(
        `SendOfferLetter/sendofferlettertocustomer?email=${myEmail}&title=Offer Letter`,
        formData
      )
        .then(() => {
          this.$emit("showAlert", {
            type: "success",
            message: "Offer Letter sent, check your email.",
          });
          this.isLoading = false;
          document.getElementById("closeLetterModal").click();
          // this.$emit("close");
        })
        .catch((error) => {
          this.$emit("showAlert", {
            type: "error",
            message: error.response.data.message,
          });
          this.isLoading = false;
        });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    downloadIndebtedness() {
      this.isLoading = true;
      ApiService.get(
        `LoanAccount/getloanletterofindebtednessbyloanaccountid/${this.loanKey}`
      )
        .then((response) => {
          this.getLetter = response.data.data;
          setTimeout(() => {
            this.downloadPDF();
          }, 3000);
        })
        .catch(() => {
          // console.log(err)/response.data
        });
    },
    downloadPDF() {
      // this.isLoading = true;
      var doc = new jsPDF("pt", "mm", [1000, 1100]);
      html2canvas(this.$refs.indebtedness, {
        width: doc.internal.pageSize.getWidth(),
        height: doc.internal.pageSize.getHeight(),
      }).then((canvas) => {
        const img = canvas.toDataURL("image/png");

        doc.addImage(
          img,
          "PNG",
          140,
          10,
          doc.internal.pageSize.getWidth(),
          doc.internal.pageSize.getHeight()
        );
        doc.save("letter_indebtedness.pdf");
        document.getElementById("closeModal").click();
        this.isLoading = false;
        this.$emit("close");
      });
    },
    getTenure(period, periodUnit) {
      periodUnit = Number(periodUnit);
      if (periodUnit == 0) {
        return `${period} day(s)`;
      }
      if (periodUnit == 1) {
        return `${period} week(s)`;
      }
      if (periodUnit == 2) {
        return `${period} month(s)`;
      }
      if (periodUnit == 3) {
        return `${period} year(s)`;
      }
    },
    getExpireDate(startDate, period, periodUnit) {
      periodUnit = Number(periodUnit);
      if (periodUnit == 0) {
        return startDate ? moment(startDate).add(period, "days") : "";
      }
      if (periodUnit == 1) {
        return startDate ? moment(startDate).add(period, "weeks") : "";
      }
      if (periodUnit == 2) {
        return startDate ? moment(startDate).add(period, "months") : "";
      }
      if (periodUnit == 3) {
        return startDate ? moment(startDate).add(period, "years") : "";
      }
    },
  },
  computed: {
    shouldShowSecurities() {
      return (
        this.data?.adminLoanProduct?.administrationProductSecuritySetting
          ?.is_collateral_enabled ||
        this.data?.adminLoanProduct?.administrationProductSecuritySetting
          ?.is_guarantors_enabled
      );
    },
    accountIsLocked() {
      return (
        this.data.accountSubState !== null &&
        this.data.accountSubState.toLowerCase() == "locked"
      );
    },
    accountIsPaidOff() {
      return (
        this.data.accountSubState !== null &&
        this.data.accountState.toLowerCase() == "closed"
      );
    },
    accountIsWrittenOff() {
      return this.data.accountSubState
        ? this.data.accountSubState.toLowerCase() === "withdrawn" ||
            this.data.accountState.toLowerCase() === "withdrawn"
        : false;
    },
    getMonthlyRepayment() {
      return this.schedules ? this.schedules[0].totalExpected : 0;
    },
    getFeesValues() {
      let customFees = [];
      if (this.customFieldData && this.customFieldData.length) {
        customFees = this.customFieldData.find(
          (set) =>
            set.fieldSetName.toLowerCase() == "fee collection confirmation"
        )?.fields;
      }
      return customFees ? customFees : [];
    },
    getGuarantorsValues() {
      let customGuarantors = [];
      let obj = {};

      if (this.customFieldData && this.customFieldData.length) {
        customGuarantors = this.customFieldData.find(
          (set) => set.fieldSetName.toLowerCase() == "guarantors"
        )?.fields;
        customGuarantors = customGuarantors ? customGuarantors : [];
      }

      customGuarantors.forEach((guarantor) => {
        if (
          [guarantor.fieldName.toLowerCase()].includes("guarantor name 1") ||
          [guarantor.fieldName.toLowerCase()].includes("guarantors name 1")
        ) {
          obj.guarantor_name_1 = guarantor.value;
        }
        if (
          [guarantor.fieldName.toLowerCase()].includes("guarantor name 2") ||
          [guarantor.fieldName.toLowerCase()].includes("guarantors name 2")
        ) {
          obj.guarantor_name_2 = guarantor.value;
        }
        if (
          [guarantor.fieldName.toLowerCase()].includes("guarantor address 1") ||
          [guarantor.fieldName.toLowerCase()].includes("guarantors address 1")
        ) {
          obj.guarantor_address_1 = guarantor.value;
        }
        if (
          [guarantor.fieldName.toLowerCase()].includes("guarantor address 2") ||
          [guarantor.fieldName.toLowerCase()].includes("guarantors address 2")
        ) {
          obj.guarantor_address_2 = guarantor.value;
        }
      });

      return obj;
    },
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format("Do MMMM, YYYY") : "";
    },
  },
  props: {
    status: {
      type: String,
      default: "ACTIVE",
    },
    isPendingState: {
      type: Boolean,
      default: false,
    },
    loanKey: String,
    data: Object,
    userCanCloseLoanAccount: Boolean,
    userCanWriteOffLoanAccount: Boolean,
    userCanClosePayOff: Boolean,
    userCanApplyFees: Boolean,
    userCanAccruedInterest: Boolean,
    userCanEditLoanAccount: Boolean,
    userCanReschedule: Boolean,
    userCanLockLoanAccounts: Boolean,
    userCanWithdraw: Boolean,
    userCanCreateReports: Boolean,
    userCanViewReports: Boolean,
    userCanRefinance: Boolean,
    userCanCreateCustomField: Boolean,
    userCanCreateLoanAccount: Boolean,
    userCanSendOfferLetter: Boolean,
    customFieldData: Array,
    schedules: Array,
  },
};
</script>
<style scoped>
.grid--items {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row dense;
}
.grid--items__cus {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 200px 350px;
  grid-auto-flow: row dense;
}
</style>
