<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Loan Statement
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">
                select date range
              </p>
            </div>

            <div class="form__item mt-5" style="display: flex; width: 100%">
              <div class="mr-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >start date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  v-model="startDate"
                />
              </div>

              <div class="ml-2" style="width: 50%">
                <label class="form__label text--black text--capital mb-3"
                  >end date</label
                >
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  :min="startDate"
                  v-model="endDate"
                />
              </div>
            </div>

            <div class="d-flex mt-5">
              <button
                class="button form__button form__button--sm w-50 mr-2"
                @click="getTransactions"
                :disabled="!startDate && !endDate"
              >
                {{ isLoading ? "loading..." : "export" }}
              </button>
              <button
                class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="$emit('close')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="template"
      style="padding: 20px; overflow: hidden; width: 80%; margin: auto"
    >
      <div style="position: relative">
        <img
          alt="template-header"
          src="@/assets/img/template_header.png"
          style="width: 100%"
        />
        <span style="position: absolute; color: white; left: 760px; top: 30px">
          <h1 style="font-size: 1.5em; font-weight: 700">Loan Statement</h1>
        </span>
      </div>

      <div style="display: flex; padding-top: 60px; width: 100%">
        <div style="width: 50%">
          <h1>ALIYU KALARAWI</h1>
          <hr style="border: 3px solid orange; width: 162px" />
        </div>
        <div style="display: flex; justify-content: flex-end; width: 50%">
          <h3 style="font-weight: 700">
            {{ startDate | moment }} to {{ endDate | moment }}
          </h3>
        </div>
      </div>

      <div style="display: flex; padding-top: 40px; width: 100%">
        <div style="display: flex; width: 50%; padding-top: 30px">
          <div>
            <p style="color: #696969; font-size: 12px">Account ID:</p>
            <h3 style="font-weight: 700">{{ accountId }}</h3>
          </div>
          <!-- <div style="padding-left:20px">
            <p style="color:#696969; font-size:12px">Product:</p>
            <h3 style="font-weight:700">Savings Plan Account</h3>
          </div> -->
        </div>
        <div style="display: flex; width: 50%; justify-content: flex-end">
          <div style="padding-right: 20px">
            <p style="color: #696969; font-size: 12px">Amount Due</p>
            <h3 style="font-weight: 700">0.00</h3>
          </div>
          <div style="padding-left: 20px">
            <p style="color: #696969; font-size: 12px">Currency</p>
            <h3 style="font-weight: 700">NGN</h3>
          </div>
        </div>
      </div>

      <div style="display: flex; padding-top: 40px; width: 100%">
        <div>
          <p style="color: #696969; font-size: 12px">Product</p>
          <h3 style="font-weight: 700">{{ productName }}</h3>
        </div>

        <!-- <div style="padding-left:20px">
            <p style="color:#696969; font-size:12px">Total Debit</p>
            <h3 style="font-weight:700">N15,000,000</h3>
        </div> -->
      </div>

      <div class="page__body" style="padding-top: 60px; min-height: 700px">
        <table class="customtable w-100" style="padding-left: 15px">
          <thead style="background: #ffdecc">
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Debit
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Credit
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Transaction Type
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Description
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity, i) in allActivities" :key="i">
              <td>
                <span
                  class="table__body__text table__text text--capital text--capital"
                >
                  {{ activity.creationDate | moment }}</span
                >
              </td>
              <td>
                <span
                  class="table__body__text table__text text--capital"
                  style="word-break: break-all"
                  >{{ activity.amount }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ "0.00" }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  activity.type
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  activity.comment
                }}</span>
              </td>
              <td>
                <span
                  class="table__body__text table__text text--capital text--capital"
                  >{{ activity.totalBalance }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="position: relative">
        <img
          alt="template-footer"
          src="@/assets/img/template_footer.png"
          style="width: 100%"
        />
        <span
          style="
            position: absolute;
            color: white;
            width: 100%;
            left: 278px;
            top: 30px;
          "
        >
          <h5 style="">
            Please address all enquiries to Finance Limited, 177A, Ligali
            Ayorinde Street, Victoria Island, Lagos,
          </h5>
          <h5 style="align-text: center">Printed on: {{ currentDate }}</h5>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.loan.service";
export default {
  name: "ViewTransaction",
  props: {
    show: Boolean,
    close: Function,
    loanKey: String,
    // accountDetails: [Array, Object],
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      isLoading: false,
      allActivities: [],
      currentDate: moment().format("DD MMMM, YYYY"),
      accountId: "",
      productName: "",
    };
  },
  mounted() {
    this.allActivities = [];
    this.startDate = "";
    this.endDate = "";
  },
  methods: {
    getTransactions() {
      this.isLoading = true;
      const data = {
        loanAccountId: this.loanKey,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      ApiService.post(`LoanAccount/getloanaccountstatementbydatesandid`, data)
        .then((response) => {
          this.allActivities = response.data.data;
          this.accountId = response.data.data[0].loanId;
          this.productName = response.data.data[0].loanName;
          this.$emit("statementAlert", {
            type: "success",
            message:
              "Account statement retrieved, template is being downloaded.",
          });
          setTimeout(() => {
            this.isLoading = false;
            this.downloadPDF();
          }, 4000);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$emit("statementAlert", {
            type: "error",
            message: err.response.data.message,
          });
        });
    },
    downloadPDF() {
      var doc = new jsPDF("pt", "mm", [1700, 1700]);
      // const doc = new jsPDF({
      //   orientation: 'p',
      //   unit: 'px',
      //   format: 'a4',
      //   hotfixes: ['px_scaling'],
      // });
      html2canvas(this.$refs.template, {
        width: doc.internal.pageSize.getWidth(),
        height: doc.internal.pageSize.getHeight(),
      }).then((canvas) => {
        const img = canvas.toDataURL("image/png");

        doc.addImage(
          img,
          "PNG",
          140,
          10,
          doc.internal.pageSize.getWidth(),
          doc.internal.pageSize.getHeight()
        );
        doc.save("loan_statement.pdf");
        this.isLoading = false;
        this.$emit("close");
      });
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
